import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerOperationTopTab from "@components/SettingResellerOperationTopTab";
import { MdDiscount } from "react-icons/md";

const ResellerDiscounts = () => {
    const couponData = [
        {
            code: "SAVE10",
            created: "2023-07-15",
            amountOff: "10%",
            isActive: true,
        },
        {
            code: "DISCOUNT20",
            created: "2023-06-25",
            amountOff: "20%",
            isActive: false,
        },
        {
            code: "WELCOME5",
            created: "2023-08-01",
            amountOff: "$5",
            isActive: true,
        },
        {
            code: "FREESHIP",
            created: "2023-07-30",
            amountOff: "Free Shipping",
            isActive: true,
        },
    ];
    return (
        <>
            <SubHeader title={"Discount"} />
            <SettingResellerOperationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="p-3 space-y-5">
                                <div className="flex items-center gap-x-4">
                                    <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                        <MdDiscount size={20} />
                                    </div>
                                    <p className="text-white">Your Discount</p>
                                    <a
                                        href="https://stripe.com"
                                        className="bg-primary text-white px-4 py-2 text-sm rounded-full hover:opacity-80"
                                    >
                                        Create a new coupan code in Stripe
                                    </a>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Code
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Created
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Amount Off
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Is active
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {couponData.map((coupon, index) => (
                                                <tr key={index}>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {coupon.code}
                                                    </td>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {coupon.created}
                                                    </td>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {coupon.amountOff}
                                                    </td>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {coupon.isActive
                                                            ? "Active"
                                                            : "Inactive"}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResellerDiscounts;
