import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerOperationTopTab from "@components/SettingResellerOperationTopTab";
import { FaUserGroup } from "react-icons/fa6";

const ResellerCustomer = () => {
    const data = [
        {
            email: "support@leadsplanet.co",
            ip: "79.184.171.162",
            status: "Active",
            totalInvoices: 23,
            registeredDate: "2 years ago",
            subUsers: 0,
            creditsUsed: 124,
            plan: "Lifetime",
            onTrial: 1,
        },
        {
            email: "sontrez@gmail.com",
            ip: "35.243.252.124",
            status: "Active",
            totalInvoices: 12,
            registeredDate: "9 months ago",
            subUsers: 0,
            creditsUsed: 0,
            plan: "Elite",
            onTrial: 0,
        },
        {
            email: "angelica.pedraza@hidronor.cl",
            ip: "70.37.81.60",
            status: "Active",
            totalInvoices: 10,
            registeredDate: "9 months ago",
            subUsers: 0,
            creditsUsed: 0,
            plan: "Starter",
            onTrial: 0,
        },
        {
            email: "engimenez@gmail.com",
            ip: "189.193.73.86",
            status: "Active",
            totalInvoices: 4,
            registeredDate: "a year ago",
            subUsers: 1,
            creditsUsed: 0,
            plan: "Starter",
            onTrial: 0,
        },
    ];
    return (
        <>
            <SubHeader title={"Customer"} />
            <SettingResellerOperationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="p-3 space-y-5">
                                <div className="flex items-center gap-x-4">
                                    <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                        <FaUserGroup size={20} />
                                    </div>
                                    <p className="text-white">
                                        281 Customers Registered to the software
                                    </p>
                                    <a
                                        href="https://stripe.com"
                                        className="bg-primary text-white px-4 py-2 text-sm rounded-full hover:opacity-80"
                                    >
                                        Open Stripe customers website
                                    </a>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Email
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Is active?
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Total invoices
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Registered date
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Sub users
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Credits Used
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Plan
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    On Trial
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className="border-t"
                                                >
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        <div>
                                                            <p className="mr-2">
                                                                {item.email}
                                                            </p>
                                                            <span className="text-sm">
                                                                ip: {item.ip}
                                                            </span>
                                                            <span className="ml-2 text-sm text-red-500 cursor-pointer">
                                                                Delete customer
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        <select className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                                                            <option className="bg-bgBodyDark">
                                                                Active
                                                            </option>
                                                            <option className="bg-bgBodyDark">
                                                                In Active
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        {item.totalInvoices}
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        {item.registeredDate}
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        {item.subUsers}
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        {item.creditsUsed}
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        {item.plan}
                                                    </td>
                                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                        {item.onTrial}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResellerCustomer;
