import React, { useEffect, useState } from "react";
import LinkTo from "./global/LinkTo";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

const SettingResellerConfigurationTopTab = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === "/reseller/installation") {
            setActiveTab(1);
        }
        if (pathname === "/reseller/product-pricing") {
            setActiveTab(2);
        }
        if (pathname === "/reseller/custom-smtp") {
            setActiveTab(3);
        }
        if (pathname === "/reseller/stripe") {
            setActiveTab(4);
        }
    }, [pathname]);

    const url = window.location.href;

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Settings | {props?.domain ? props?.domain : "Btrendy"}</title>
                <link rel="canonical" href={url} />
            </Helmet>
            <div className="bg-bgBodyLight border-b-[3px] border-[#ccc] min-h-[50px]z mb-12">
                <div className="flex flex-wrap">
                    <LinkTo
                        to="/reseller/installation"
                        className={`mr-[20px] min-w-[80px] text-center relative leading-[33px] text-white  before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] block before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0 ${
                            activeTab === 1
                                ? "font-semibold before:w-full"
                                : "font-normal before:w-0"
                        }`}
                        text="Installation"
                    />
                    {/* <LinkTo
                        to="/reseller/product-pricing"
                        className={`mr-[20px] min-w-[80px] text-center relative leading-[33px] text-white  before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] block before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0 ${
                            activeTab === 2
                                ? "font-semibold before:w-full"
                                : "font-normal before:w-0"
                        }`}
                        text="Product & Pricing"
                    /> */}
                    {/* <LinkTo
                        to="/reseller/custom-smtp"
                        className={`mr-[20px] min-w-[80px] text-center relative leading-[33px] text-white  before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] block before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0 ${
                            activeTab === 3
                                ? "font-semibold before:w-full"
                                : "font-normal before:w-0"
                        }`}
                        text="Custom SMTP"
                    /> */}
                    {/* <LinkTo
                        to="/reseller/stripe"
                        className={`mr-[20px] min-w-[80px] text-center relative leading-[33px] text-white  before:ease-[cubic-bezier(0.26,1.8,0.17,0.96)] bg-transparent text-[14px] py-[0.5rem] px-[1rem] block before:content-[''] before:absolute before:bottom-[-3px] before:left-0 hover:before:w-full before:duration-[0.6s] before:delay-[0.1s] before:bg-primary before:h-[3px] focus-visible:outline-0 ${
                            activeTab === 4
                                ? "font-semibold before:w-full"
                                : "font-normal before:w-0"
                        }`}
                        text="Stripe"
                    /> */}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
      domain: state?.domain?.data?.company_name,
    };
};
  
export default connect(mapStateToProps, null)(SettingResellerConfigurationTopTab);