import CommissionGridPopup from "@components/Popup/CommissionGridPopup";
import { useRef } from "react";
import { BsCash } from "react-icons/bs";
import { FaChartLine, FaUserCheck, FaUserPlus } from "react-icons/fa";
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    ResponsiveContainer,
    Bar,
} from "recharts";
import PaymentTable from "./SalesTracked";

const RevenueSummary = () => {

    const commissionRef = useRef(null);

    const data = [
        {
            date: "2023-11-07",
            monthly: 5,
            yearly: 4,
            quarterly: 3,
            semiAnnual: 2,
            nineMonths: 1,
            mrr: 500,
        },
        {
            date: "2023-12-07",
            monthly: 4,
            yearly: 3,
            quarterly: 3,
            semiAnnual: 2,
            nineMonths: 1,
            mrr: 450,
        },
        {
            date: "2024-01-07",
            monthly: 4,
            yearly: 3,
            quarterly: 2,
            semiAnnual: 2,
            nineMonths: 1,
            mrr: 400,
        },
        {
            date: "2024-02-07",
            monthly: 4,
            yearly: 2,
            quarterly: 2,
            semiAnnual: 1,
            nineMonths: 1,
            mrr: 350,
        },
        {
            date: "2024-03-07",
            monthly: 3,
            yearly: 2,
            quarterly: 2,
            semiAnnual: 1,
            nineMonths: 1,
            mrr: 300,
        },
        {
            date: "2024-04-07",
            monthly: 2,
            yearly: 2,
            quarterly: 1,
            semiAnnual: 1,
            nineMonths: 0,
            mrr: 200,
        },
        {
            date: "2024-05-07",
            monthly: 1,
            yearly: 1,
            quarterly: 1,
            semiAnnual: 0,
            nineMonths: 0,
            mrr: 100,
        },
    ];

    return (
      <>
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
            <div className="p-3">
                <div className="flex justify-between items-center py-3 rounded-t-lg">
                    <div className="flex items-center gap-x-4">
                        <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                            <FaChartLine size={20} />
                        </div>
                        <p className="text-white">Revenue Summary</p>
                    </div>
                </div>
                <div className="w-full h-full overflow-x-auto">
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={data}
                            margin={{ top: 10, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip cursor={{fill: 'transparent'}} />
                            <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: 20 }} />
                            <Bar
                                dataKey="monthly"
                                stackId="a"
                                fill="#8884d8"
                                name="Active subscriptions [Monthly]"
                            />
                            <Bar
                                dataKey="yearly"
                                stackId="a"
                                fill="#c897d6"
                                name="Active subscriptions [Yearly]"
                            />
                            <Bar
                                dataKey="quarterly"
                                stackId="a"
                                fill="#82ca9d"
                                name="Active subscriptions [3 Months period]"
                            />
                            <Bar
                                dataKey="semiAnnual"
                                stackId="a"
                                fill="#ffc658"
                                name="Active subscriptions [6 Months period]"
                            />
                            <Bar
                                dataKey="nineMonths"
                                stackId="a"
                                fill="#83a6ed"
                                name="Active subscriptions [9 Months period]"
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div className="mt-10 grid grid-cols-2 w-full gap-5">
                  <div className="bg-bgBodyDark rounded-lg p-3">
                    <div className="flex items-center gap-5">
                      <div className="h-12 w-12 shrink-0 bg-green-500/60 inline-flex justify-center items-center rounded-full">
                        <BsCash size={20} className="text-white" />
                      </div>
                      <div>
                        <p className="text-white font-semibold text-2xl">65 %</p>
                        <p className="text-white/70">Revenue sharing</p>
                        <button onClick={()=>commissionRef.current?.open()} className="text-blue-500">Commission grid</button>
                      </div>
                    </div>
                  </div>
                  <div className="bg-bgBodyDark rounded-lg p-3">
                    <div className="flex items-center gap-5">
                      <div className="h-12 w-12 shrink-0 bg-green-500/60 inline-flex justify-center items-center rounded-full">
                        <FaUserCheck size={20} className="text-white" />
                      </div>
                      <div>
                        <div className="flex items-center">
                          <p className="text-white font-semibold text-2xl border-r pr-3">0</p>
                          <p className="text-white font-semibold text-2xl pl-3">$ 0.00</p>
                        </div>
                        <p className="text-white/70">Paying / Estimated MRR in USD ($). Update every 6 hours.</p>
                        <a href="https://dashboard.stripe.com/customers" target="_blank" className="text-blue-500">View Users</a>
                      </div>
                    </div>
                  </div>
                  <div className="bg-bgBodyDark rounded-lg p-3">
                    <div className="flex items-center gap-5">
                      <div className="h-12 w-12 shrink-0 bg-orange-500/60 inline-flex justify-center items-center rounded-full">
                        <FaUserPlus size={20} className="text-white" />
                      </div>
                      <div>
                        <p className="text-white font-semibold text-2xl">200</p>
                        <p className="text-white/70">Registered</p>
                        <a href="https://dashboard.stripe.com/customers" target="_blank" className="text-blue-500">View users</a>
                      </div>
                    </div>
                  </div>
                  <div className="bg-bgBodyDark rounded-lg p-3">
                    <div className="flex items-center gap-5">
                      <div className="h-12 w-12 shrink-0 bg-green-500/60 inline-flex justify-center items-center rounded-full">
                        <BsCash size={20} className="text-white" />
                      </div>
                      <div>
                        <p className="text-white font-semibold text-2xl">0</p>
                        <p className="text-white/70">Trial</p>
                        <a href="https://dashboard.stripe.com/customers" target="_blank" className="text-blue-500">View users</a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <CommissionGridPopup ref={commissionRef} />
      </>
    );
};

export default RevenueSummary;
