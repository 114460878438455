import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerOperationTopTab from "@components/SettingResellerOperationTopTab";
import RevenueSummary from "./RevenueSummary";
import SalesTracked from "./SalesTracked";

const ResellerRevenue = (props) => {
    return (
        <>
            <SubHeader title={"Revenue"} />
            <SettingResellerOperationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div
                            class="flex items-center p-4 text-sm border border-blue-300 rounded-lg bg-blue-50"
                            role="alert"
                        >
                            <span class="sr-only">Info</span>
                            <p>
                                Active subscriptions chart is updated every 6 hours. Demo accounts are not added to this chart, only paying customers with a credit card in renewing state.
                            </p>
                        </div>
                        <RevenueSummary />
                        <SalesTracked />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResellerRevenue;
