import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerConfigurationTopTab from "@components/SettingResellerConfigurationTopTab";
import SoftwareBundles from "./SoftwareBundles";
import BusinessModel from "./BusinessModel";
import PricingPlan from "./PricingPlan";


const ResellerProductPricing = () => {

    return (
        <>
            <SubHeader title={"Product & Pricing"} />
            <SettingResellerConfigurationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <SoftwareBundles />
                        <BusinessModel />
                        <PricingPlan />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResellerProductPricing;
