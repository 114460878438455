import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerConfigurationTopTab from "@components/SettingResellerConfigurationTopTab";
import React from "react";
import { IoIosSettings } from "react-icons/io";

const ResellerStripe = () => {
    return (
        <>
            <SubHeader title={"Stripe"} />
            <SettingResellerConfigurationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="flex items-center gap-x-4 py-3">
                                <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1.7em"
                                        height="1.7em"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth={0}
                                        baseProfile="tiny"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            stroke="none"
                                            d="M18 16.184v-1.851c0-1.93-1.57-3.5-3.5-3.5-.827 0-1.5-.673-1.5-1.5V7.816A2.997 2.997 0 0 0 15 5c0-1.654-1.346-3-3-3S9 3.346 9 5c0 1.302.839 2.401 2 2.815v1.518c0 .827-.673 1.5-1.5 1.5-1.93 0-3.5 1.57-3.5 3.5v1.851A2.997 2.997 0 0 0 4 19c0 1.654 1.346 3 3 3s3-1.346 3-3a2.997 2.997 0 0 0-2-2.816v-1.851c0-.827.673-1.5 1.5-1.5.979 0 1.864-.407 2.5-1.058a3.487 3.487 0 0 0 2.5 1.058c.827 0 1.5.673 1.5 1.5v1.851A2.997 2.997 0 0 0 14 19c0 1.654 1.346 3 3 3s3-1.346 3-3a2.997 2.997 0 0 0-2-2.816zM7 20a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2zm5-16a1.001 1.001 0 1 1-1 1c0-.551.448-1 1-1zm5 16a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2z"
                                        />
                                    </svg>
                                </div>
                                <p className="text-white">
                                    Connect your stripe account
                                </p>
                                <a
                                    href="https://dashboard.stripe.com/login"
                                    target="_blank"
                                    className="bg-primary text-white px-4 py-2 text-sm rounded-full hover:opacity-80"
                                >
                                    Open your stripe.com dashboard
                                </a>
                            </div>
                            <div className="mt-3 space-y-3">
                                <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-5 gap-y-3">
                                    <div className="flex flex-col gap-2">
                                        <label
                                            htmlFor="publishable"
                                            className="text-white"
                                        >
                                            Publishable key
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="pk_live_ONADJDIhe837uhue873638UxwJxqYIPbU"
                                            className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            id="publishable"
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <label
                                            htmlFor="secret"
                                            className="text-white"
                                        >
                                            Secret key
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="sk_live_ONADJDIhe837uhue873638UxwJxqYIPbU"
                                            className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            id="secret"
                                        />
                                    </div>
                                </div>
                                <button className="bg-primary px-4 py-2 rounded text-white hover:opacity-80">
                                    Update Stripe configuration
                                </button>
                            </div>
                        </div>
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="flex items-center gap-x-4 py-3">
                                <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                    <IoIosSettings size={20} />
                                </div>
                                <p className="text-white">Webhook</p>
                                <a
                                    href="https://dashboard.stripe.com/login"
                                    target="_blank"
                                    className="bg-primary text-white px-4 py-2 text-sm rounded-full hover:opacity-80"
                                >
                                    Open your stripe.com webhooks
                                </a>
                            </div>
                            <div className="mt-3 space-y-3">
                                <div className="py-3 border-y px-3">
                                    <p className="text-white">
                                        Installed Webhook
                                    </p>
                                </div>
                                <div className="flex justify-between items-start">
                                    <p className="text-white w-full max-w-2xl">
                                        You can't start selling, your webhook is
                                        not created and not working, please
                                        update public and private keys. If you
                                        need help contact our live support to
                                        help you.
                                    </p>
                                    <button className="bg-transparent border hover:bg-white transition-all duration-500 ease-in-out px-4 py-2 rounded text-red-500 hover:opacity-80">
                                        Not installed
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResellerStripe;
