import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerOperationTopTab from "@components/SettingResellerOperationTopTab";
import React from "react";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ResellerInvoice = () => {
    const navigate = useNavigate();
    const hasPlans = useSelector(state => state.domain?.data?.hasPlans);

    if(!hasPlans){
        navigate('/');
    }
    
    return (
        <>
            <SubHeader title={"Invoices"} />
            <SettingResellerOperationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="p-3 space-y-5">
                                <div className="flex items-center gap-x-4">
                                    <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                        <FaFileInvoiceDollar size={20} />
                                    </div>
                                    <p className="text-white">Invoices</p>
                                </div>
                            </div>

                            <div className="h-60 flex justify-center items-center flex-col gap-y-4">
                                <p className="w-20 h-10 inline-flex rounded-md justify-center items-center text-white font-semibold text-xl bg-primary">
                                    stripe
                                </p>
                                <p className="text-white text-center">Invoices created for your customers, <br /> are available on your Stripe account directly.</p>
                                <a href="https://dashboard.stripe.com/login" target="_blank" className="bg-primary text-white px-4 py-2 rounded hover:opacity-80">Click here to open Stripe to downlaod</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResellerInvoice;
