import { useState } from "react";
import { connect } from "react-redux";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowRight, AiOutlineCheck } from "react-icons/ai";
import Capterra from "@assets/capterra.webp";
import TrustPilot from "@assets/trustpilot.webp";
import Rating from "@assets/rating.webp";
import Bottom from "@assets/pricing/bottom.webp";
import Side from "@assets/pricing/side.webp";
import End from "@assets/pricing/pricingend.webp";
import { RxCross2 } from "react-icons/rx";
import { BsDashLg } from "react-icons/bs";
import { FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";

const PricingPlans = (props) => {
  const navigate = useNavigate();
  const hasPlan = useSelector(state => state.domain?.data?.hasPlans);


  const [billingType, setBillingType] = useState("month");
  const handleBillingType = (newAlignment) => {
    setBillingType(newAlignment);
  };
  if(!hasPlan){
      return (
        <div className="text-white h-screen flex justify-center flex-col gap-3 items-center">
          <h1 className="text-red-600 font-bold text-2xl">Access Denied!</h1>
          <p className="text-lg">You are not authorized to access this page</p>
        </div>
      )

  }

  return (
    <div className="containers relative">
      <div className="w-full max-w-7xl mx-auto">
        <div className="mb-12 pb-md-5 md:flex items-center gap-5 justify-center flex-col">
          <div className="mb-12 md:!mb-0 text-center">
            <h1 className="lg:text-[60px] text-white font-firaSans text-[30px] font-bold">
              Select Your Ideal Plan
            </h1>
          </div>
          <div className="flex flex-wrap justify-center">
            <label className="my-6 flex items-center bg-bgBodyDark py-1 px-1 rounded-full">
              <p
                onClick={() => handleBillingType("year")}
                className={
                  "font-medium " +
                  (billingType === "year"
                    ? "bg-black rounded-full p-4 text-gray-300"
                    : "text-gray-300 p-4") +
                  " flex cursor-pointer items-center gap-x-3 ml-1"
                }
              >
                Yearly 
              </p>
              <span className="bg-[#0c2112] ml-2 py-1 px-2 rounded-full text-[#4ccc8f] font-semibold">Save 20%</span>
              <p
                onClick={() => handleBillingType("month")}
                className={
                  "font-medium " +
                  (billingType === "month"
                    ? "bg-black rounded-full p-4 text-gray-300"
                    : "text-gray-300 p-4") +
                  " flex cursor-pointer items-center gap-x-3 ml-10 mr-1"
                }
              >
                Monthly
              </p>
            </label>
          </div>
        </div>
        {billingType === "month" && (
          <div className="relative">
            <div className="grid grid-cols-9 gap-4 mb-12">
              {props.monthlyPlans &&
                props.monthlyPlans.length &&
                props.monthlyPlans
                  .filter((i) => i.interval === "month")
                  .map((plan, index) => {
                    return (
                      <div
                        key={index}
                        className={`${index === 1 ? 'border-primary' : 'border-borderDark'} h-max my-auto border-[1px] lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-9 pb-10 rounded-3xl`}
                      >
                        {
                          index === 1 && <div className="bg-primary rounded-t-3xl h-14 gap-1 flex justify-center items-center">
                              <FaStar color="#e6a12d" size={17}  /> <p className="text-white font-semibold">Most Popular</p>
                          </div>
                        }
                        <div className="border-b  border-borderDark pb-4 px-5 pt-5">
                          <h6 className="text-[25px] font-firaSans font-semibold text-white">{plan.name}</h6>
                          <p className="font-medium text-lg font-firaSans text-[#7a7a7a]">
                            {index === 0
                              ? "Find, analyze and export Influencers."
                              : index === 1
                              ? "Manage campaigns end-to-end."
                              : "Streamline everything."}
                          </p>
                        </div>
                        <p className="font-semibold pt-5 text-white text-5xl px-5">
                          ${plan.price}{" "}
                          <sup className="font-normal text-[#7a7a7a] text-lg">per month</sup>
                        </p>
                        <div className="mt-4 px-5">
                          {index === 0 ? (
                            <ul className="mt-5 space-y-4">
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.credits ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.credits} Credits{" "}
                                    {plan?.interval === "one_time" && "/mo"}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Discover 300M+ Influencers
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Followers Scanner
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Unlimited Influencer Lists
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Influencer emailing
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Data Export (CSV, Json)
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Posts Tracking
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-[#393a3c]">
                                <BsDashLg size={17} />
                                <p className="text-[14px] font-medium">
                                  Workspaces
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-[#393a3c]">
                                <BsDashLg size={17} />
                                <p className="text-[14px] font-medium">
                                  Team members
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-[#393a3c]">
                                <BsDashLg size={17} />
                                <p className="text-[14px] font-medium">
                                  API
                                </p>
                              </li>
                            </ul>
                          ) : index === 1 ? (
                            <ul className="mt-5 space-y-4">
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.credits ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.credits} Credits{" "}
                                    {plan?.interval === "one_time" && "/mo"}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Discover 300M+ Influencers
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Followers Scanner
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Unlimited Influencer Lists
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Influencer emailing
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Data Export (CSV, Json)
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Posts Tracking
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.sub_account > 0 ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.sub_account} Workspaces
                                  </p>
                                ) : (
                                  <p className="text-[14px] font-medium">
                                    0 Workspaces
                                  </p>
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.team_member > 0 ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.team_member} Team members
                                  </p>
                                ) : (
                                  <p className="text-[14px] font-medium">
                                    0 Team members
                                  </p>
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-[#393a3c]">
                                <BsDashLg size={17} />
                                <p className="text-[14px] font-medium">
                                  API
                                </p>
                              </li>
                            </ul>
                          ) : (
                            <ul className="mt-5 space-y-4">
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.credits ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.credits} Credits{" "}
                                    {plan?.interval === "one_time" && "/mo"}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Discover 300M+ Influencers
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Followers Scanner
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Unlimited Influencer Lists
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Influencer emailing
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Data Export (CSV, Json)
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  Posts Tracking
                                </p>
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.sub_account > 0 ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.sub_account} Workspaces
                                  </p>
                                ) : (
                                  <p className="text-[14px] font-medium">
                                    0 Workspaces
                                  </p>
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                {plan.planFeature &&
                                plan.planFeature.team_member > 0 ? (
                                  <p className="text-[14px] font-medium">
                                    {plan.planFeature.team_member} Team members
                                  </p>
                                ) : (
                                  <p className="text-[14px] font-medium">
                                    0 Team members
                                  </p>
                                )}
                              </li>
                              <li className="flex items-center gap-3 text-white">
                                <AiOutlineCheck size={17} />
                                <p className="text-[14px] font-medium">
                                  API
                                </p>
                              </li>
                            </ul>
                          )}
                          <div className="flex justify-center items-center mt-5">
                            <button
                              onClick={() =>
                                navigate(`/brand/register/${plan.id}/trial`)
                              }
                              className={`relative w-full font-firaSan bg-primary shadow-lg max-w-[280px] rounded-lg group text-white text-base px-3 py-3 font-medium`}
                            >
                              Start Free Trial
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}

        {billingType === "year" && (
          <div className="relative">
            <div className="grid grid-cols-9 gap-4 mb-12">
              {props.yearlyPlans &&
                props.yearlyPlans.length &&
                props.yearlyPlans.map((plan, index) => {
                  return (
                    <div
                      key={index}
                      className={`${index === 1 ? 'border-primary' : 'border-borderDark'} h-max my-auto border-[1px] lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-9 pb-10 rounded-3xl`}
                    >
                      {
                        index === 1 && <div className="bg-primary rounded-t-3xl h-14 gap-1 flex justify-center items-center">
                            <FaStar color="#e6a12d" size={17}  /> <p className="text-white font-semibold">Most Popular</p>
                        </div>
                      }
                      <div className="border-b  border-borderDark pb-4 px-5 pt-5">
                        <h6 className="text-[25px] font-firaSans font-semibold text-white">{plan.name}</h6>
                        <p className="font-medium text-lg font-firaSans text-[#7a7a7a]">
                          {index === 0
                            ? "Find, analyze and export Influencers."
                            : index === 1
                            ? "Manage campaigns end-to-end."
                            : "Streamline everything."}
                        </p>
                      </div>
                      <p className="font-semibold pt-5 text-white text-5xl px-5">
                        ${plan.price}{" "}
                        <sup className="font-normal text-[#7a7a7a] text-lg">per month</sup>
                      </p>
                      <div className="mt-4 px-5">
                        {index === 0 ? (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                Workspaces
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                Team members
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        ) : index === 1 ? (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.sub_account > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.sub_account} Workspaces
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Workspaces
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.team_member > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.team_member} Team members
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Team members
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-[#393a3c]">
                              <BsDashLg size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        ) : (
                          <ul className="mt-5 space-y-4">
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.credits ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.credits} Credits{" "}
                                  {plan?.interval === "one_time" && "/mo"}
                                </p>
                              ) : (
                                ""
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Discover 300M+ Influencers
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Followers Scanner
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Unlimited Influencer Lists
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Influencer emailing
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Data Export (CSV, Json)
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                Posts Tracking
                              </p>
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.sub_account > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.sub_account} Workspaces
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Workspaces
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              {plan.planFeature &&
                              plan.planFeature.team_member > 0 ? (
                                <p className="text-[14px] font-medium">
                                  {plan.planFeature.team_member} Team members
                                </p>
                              ) : (
                                <p className="text-[14px] font-medium">
                                  0 Team members
                                </p>
                              )}
                            </li>
                            <li className="flex items-center gap-3 text-white">
                              <AiOutlineCheck size={17} />
                              <p className="text-[14px] font-medium">
                                API
                              </p>
                            </li>
                          </ul>
                        )}
                        <div className="flex justify-center items-center mt-5">
                          <button
                            onClick={() =>
                              navigate(`/brand/register/${plan.id}/trial`)
                            }
                            className={`relative w-full font-firaSan bg-primary shadow-lg max-w-[280px] rounded-lg group text-white text-base px-3 py-3 font-medium`}
                          >
                            Start Free Trial
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        <div className="bg-white hidden px-5 py-4 shadow-2xl rounded-2xl mb-16">
          <div className="lg:w-96 w-60 text-center bg-[#C1FFE6]">
            <p className="font-semibold text-lg">Btrendy Price Match</p>
          </div>
          <div className="text-center mt-3">
            <p className="font-medium text-lg">
              Share with us a lower quote from anyone else and we will beat it
              with 15% Discount
            </p>
          </div>
        </div>

        <div className="hidden gap-4 mb-12 justify-center items-center flex-col">
          <p className="border border-[#000000] text-white rounded-full px-4 py-2 font-bold lg:text-2xl text-lg inline-block">
            + See all features and compare plans
          </p>
          <p className="font-bold lg:text-2xl text-lg text-white">
            Trusted by 1000+ fastest growing brands worldwide
          </p>
          <div className="flex gap-3">
            <img src={Capterra} width={120} alt="Capterra" />
            <img src={TrustPilot} width={180} alt="TrustPilot" />
          </div>
        </div>
      </div>
      <div className="hidden absolute top-[35%] -left-72">
        <img src={Side} alt="Side" width={300} />
      </div>
      <div className="hidden grid-cols-12 gap-3 relative">
        <div className="absolute top-0 -right-[75px] z-10 xl:block lg:block hidden">
          <img src={End} alt="End" width={100} />
        </div>
        <div className="xl:col-span-4 px-0 relative mb-[50px] hidden xl:block">
          <div className="flex flex-col items-start justify-end p-4 item min-h-[200px]">
            <img src={Rating} alt="Rating" width={300} />
          </div>
          <div className="p-[1rem] flex flex-col border-r-0">
            <ul className="list-unstyled text-left space-y-4">
              <li className="font-semibold text-white text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Credits
                <span className="font-normal text-white text-sm">
                  Each search or influencer profile open costs 1 credit
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Social networks
                <span className="font-normal text-white text-sm">
                  Social networks in which you can find, analyze and recruit
                  influencers
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Filter through 170M+ influencers
                <span className="font-normal text-white text-sm">
                  The amount of influencers available inside Btrendy's database
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Analyze influencers
                <span className="font-normal text-white text-sm">
                  Analyze any influencer for audience demographics, fake
                  followers
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Export results
                <span className="font-normal text-white text-sm">
                  Export your influencers search results in a neat CSV, with all
                  metrics available
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Audience overlap
                <span className="font-normal text-white text-sm">
                  Ensure you are reaching the largest unique audience possible
                  or find audience with a crossover to reinforce your message
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Lists
                <span className="font-normal text-white text-sm">
                  CA space for you to organize your influencers in one place,
                  use Kanban or lists, add statuses and more
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                IRM
                <span className="font-normal text-white text-sm">
                  Influencer relationship manager consists of different modules
                  like email outreach, payment escrow, social listening &
                  reporting
                </span>
              </li>
              <li className="font-semibold text-white text-[16px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                INTEGRATIONS
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Gmail
                <span className="font-normal text-white text-sm">
                  Directly outreach to influencers on Btrendy using your own
                  email address
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                SMTP
                <span className="font-normal text-white text-sm">
                  Integrate lnfluencify with any email platform using SMTP for
                  influencers email outreach
                </span>
              </li>

              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Shopify
                <span className="font-normal text-white text-sm">
                  Import your products to Btrendy and use them to pay
                  influencers, Track sales and revenue
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Google Analytics
                <span className="font-normal text-white text-sm">
                  Get your website traffic, sales and other metrics integrated
                  into your Btrendy account
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Public APIs
                <span className="font-normal text-white text-sm">
                  Get your APIs credentials and access to Public APIs
                </span>
              </li>
              <li className="font-semibold text-white text-[16px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                COLLABORATION
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Team members access
                <span className="font-normal text-white text-sm">
                  Invite team members and clients to your account, control thier
                  access and what they can see or do
                </span>
              </li>
              <li className="font-semibold text-[14px] text-white leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                Sub-Accounts
                <span className="font-normal text-white text-sm">
                  Create different brands under your main account, each
                  subaccount has it's own team
                </span>
              </li>
            </ul>
          </div>
        </div>
        {billingType === "month" && (
          <div className="xl:col-span-8 col-span-12 gap-3 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
            {props.monthlyPlans && props.monthlyPlans.length
              ? props.monthlyPlans
                  .filter((i) => i.interval === "month")
                  .map((plan, index) => (
                    <div
                      className={
                        "relative rounded-2xl mb-[50px] border-[1px] border-[#dddddd]" +
                        (plan.isRecommended ? " bg-[#f9f9f9]" : "")
                      }
                      key={index}
                    >
                      {plan.isRecommended ? (
                        <div className="hidden absolute w-full top-[-40px] ">
                          <p className="text-white">Most Popular</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`text-center py-4 xl:!px-4 px-2 flex flex-col items-center justify-center min-h-[200px] ${
                          index === 0
                            ? "bg-[#FFE1A1]"
                            : index === 1
                            ? "bg-[#c1e5ff]"
                            : "bg-[#f0c0f5]"
                        } rounded-2xl rounded-b-none`}
                      >
                        <h5 className="card-title text-2xl font-bold">
                          {plan.name}
                        </h5>
                        <h6 className=" text-[16px] invisible">
                          ${plan.price}/M
                        </h6>
                        <i className="invisible">Free Trial (14 Days)</i>
                        <button
                          onClick={() =>
                            navigate(`/brand/register/${plan.id}/trial`)
                          }
                          className={`relative w-full shadow-lg max-w-[280px] ${
                            index === 0
                              ? "bg-[#DDB000]"
                              : index === 1
                              ? "bg-[#2b54f6]"
                              : "bg-[#a42bf6]"
                          } rounded-[29px] group text-black text-base fill-black px-3 py-2 font-medium`}
                        >
                          <span className="flex justify-center">
                            <span className="bg-white rounded-full p-2 absolute top-1/2 left-[7px] -translate-y-[50%] transition-all duration-300 ease-linear mr-1 group-hover:left-full group-hover:-translate-x-[130%] group-hover:-translate-y-[50%]">
                              <AiOutlineArrowRight className="font-bold" />
                            </span>
                            <span className="transition-transform duration-200 ease-linear translate-x-0 inline-block group-hover:-translate-x-[30px]">
                              {plan?.interval === "one_time"
                                ? "Start now"
                                : "Start your 14-Days free trial"}
                            </span>
                          </span>
                        </button>
                      </div>
                      <div
                        className={`flex flex-col p-2 lg:!p-[1rem] ${
                          index === 0
                            ? "bg-[#FFE1A1]"
                            : index === 1
                            ? "bg-[#c1e5ff]"
                            : "bg-[#f0c0f5]"
                        } rounded-2xl rounded-t-none`}
                      >
                        <ul className="list-unstyled text-center space-y-4">
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            {index === 0 ? (
                              <RxCross2 className="text-[#000000]" size={19} />
                            ) : (
                              <FiCheck className="text-[#000000]" size={19} />
                            )}
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>

                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            {index === 2 ? (
                              <FiCheck className="text-[#000000]" size={19} />
                            ) : (
                              <RxCross2 className="text-[#000000]" size={19} />
                            )}{" "}
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                        </ul>
                      </div>
                      {plan.isRecommended ? (
                        <div className="hidden absolute w-full bottom-[-50px]">
                          <p className="text-white">Most Popular</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
              : ""}
          </div>
        )}
        {/* {billingType === "lifetime" && (
          <div className="xl:col-span-8 col-span-12 gap-3 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
            {props.monthlyPlans && props.monthlyPlans.length
              ? props.monthlyPlans
                  .filter((i) => i.interval === "one_time")
                  .map((plan, index) => (
                    <div
                      className={
                        "relative rounded-2xl mb-[50px] border-[1px] border-[#dddddd]" +
                        (plan.isRecommended ? " bg-[#f9f9f9]" : "")
                      }
                      key={index}
                    >
                      {plan.isRecommended ? (
                        <div className="hidden absolute w-full top-[-40px] ">
                          <p className="text-white">Most Popular</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`text-center py-4 xl:!px-4 px-2 flex flex-col items-center justify-center min-h-[200px] ${
                          index === 0
                            ? "bg-[#FFE1A1]"
                            : index === 1
                            ? "bg-[#c1e5ff]"
                            : "bg-[#f0c0f5]"
                        } rounded-2xl rounded-b-none`}
                      >
                        <h5 className="card-title text-2xl font-bold">
                          {plan.name}
                        </h5>
                        <h6 className=" text-[16px] invisible">
                          ${plan.price}/M
                        </h6>
                        <i className="invisible">Free Trial (14 Days)</i>
                        <button
                          onClick={() =>
                            navigate(`/brand/register/${plan.id}/trial`)
                          }
                          className={`relative w-full shadow-lg max-w-[280px] ${
                            index === 0
                              ? "bg-[#DDB000]"
                              : index === 1
                              ? "bg-[#2b54f6]"
                              : "bg-[#a42bf6]"
                          } rounded-[29px] group text-black text-base fill-black px-3 py-2 font-medium`}
                        >
                          <span className="flex justify-center">
                            <span className="bg-white rounded-full p-2 absolute top-1/2 left-[7px] -translate-y-[50%] transition-all duration-300 ease-linear mr-1 group-hover:left-full group-hover:-translate-x-[130%] group-hover:-translate-y-[50%]">
                              <AiOutlineArrowRight className="font-bold" />
                            </span>
                            <span className="transition-transform duration-200 ease-linear translate-x-0 inline-block group-hover:-translate-x-[30px]">
                              {plan?.interval === "one_time"
                                ? "Start now"
                                : "Start your 14-Days free trial"}
                            </span>
                          </span>
                        </button>
                      </div>
                      <div
                        className={`flex flex-col p-2 lg:!p-[1rem] ${
                          index === 0
                            ? "bg-[#FFE1A1]"
                            : index === 1
                            ? "bg-[#c1e5ff]"
                            : "bg-[#f0c0f5]"
                        } rounded-2xl rounded-t-none`}
                      >
                        <ul className="list-unstyled text-center space-y-4">
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>

                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                          <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                            <FiCheck className="text-[#000000]" size={19} />
                            <span className="invisible">Demo</span>
                          </li>
                        </ul>
                      </div>
                      {plan.isRecommended ? (
                        <div className="hidden absolute w-full bottom-[-50px]">
                          <p className="text-white">Most Popular</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
              : ""}
          </div>
        )} */}
        {billingType === "year" && (
          <div className="xl:col-span-8 col-span-12 gap-3 grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1">
            {props.yearlyPlans && props.yearlyPlans.length
              ? props.yearlyPlans.map((plan, index) => (
                  <div
                    className={
                      "relative rounded-2xl mb-[50px] border-[1px] border-[#dddddd]" +
                      (plan.isRecommended ? " bg-[#f9f9f9]" : "")
                    }
                    key={index}
                  >
                    {plan.isRecommended ? (
                      <div className="hidden absolute w-full top-[-40px] ">
                        <p className="text-white">Most Popular</p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`text-center py-4 xl:!px-4 px-2 flex flex-col items-center justify-center min-h-[200px] ${
                        index === 0
                          ? "bg-[#FFE1A1]"
                          : index === 1
                          ? "bg-[#c1e5ff]"
                          : "bg-[#f0c0f5]"
                      } rounded-2xl rounded-b-none`}
                    >
                      <h5 className="card-title text-2xl font-bold">
                        {plan.name}
                      </h5>
                      <h6 className=" text-[16px] invisible">
                        ${plan.price}/M
                      </h6>
                      <i className="invisible">Free Trial (14 Days)</i>
                      <button
                        onClick={() =>
                          navigate(`/brand/register/${plan.id}/trial`)
                        }
                        className={`relative w-full shadow-lg max-w-[280px] ${
                          index === 0
                            ? "bg-[#DDB000]"
                            : index === 1
                            ? "bg-[#2b54f6]"
                            : "bg-[#a42bf6]"
                        } rounded-[29px] group text-black text-base fill-black px-3 py-2 font-medium`}
                      >
                        <span className="flex justify-center">
                          <span className="bg-white rounded-full p-2 absolute top-1/2 left-[7px] -translate-y-[50%] transition-all duration-300 ease-linear mr-1 group-hover:left-full group-hover:-translate-x-[130%] group-hover:-translate-y-[50%]">
                            <AiOutlineArrowRight className="font-bold" />
                          </span>
                          <span className="transition-transform duration-200 ease-linear translate-x-0 inline-block group-hover:-translate-x-[30px]">
                            Start your 14-Days free trial
                          </span>
                        </span>
                      </button>
                    </div>
                    <div
                      className={`flex flex-col p-2 lg:!p-[1rem] ${
                        index === 0
                          ? "bg-[#FFE1A1]"
                          : index === 1
                          ? "bg-[#c1e5ff]"
                          : "bg-[#f0c0f5]"
                      } rounded-2xl rounded-t-none`}
                    >
                      <ul className="list-unstyled text-center space-y-4">
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          {index === 0 ? (
                            <RxCross2 className="text-[#000000]" size={19} />
                          ) : (
                            <FiCheck className="text-[#000000]" size={19} />
                          )}
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>

                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          {index === 2 ? (
                            <FiCheck className="text-[#000000]" size={19} />
                          ) : (
                            <RxCross2 className="text-[#000000]" size={19} />
                          )}{" "}
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="text-[14px] leading-[14px] h-[50px] flex flex-col justify-center py-[8px]">
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                        <li className="leading-[14px] h-[50px] flex items-center justify-center py-[8px]">
                          <FiCheck className="text-[#000000]" size={19} />
                          <span className="invisible">Demo</span>
                        </li>
                      </ul>
                    </div>
                    {plan.isRecommended ? (
                      <div className="hidden absolute w-full bottom-[-50px]">
                        <p className="text-white">Most Popular</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ))
              : ""}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    monthlyPlans: state.SettingSubscriptionReducer.monthlyPlans,
    yearlyPlans: state.SettingSubscriptionReducer.yearlyPlans,
  };
};

export default connect(mapStateToProps, null)(PricingPlans);
