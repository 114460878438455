import React from "react";
import { FaMoneyCheckDollar } from "react-icons/fa6";

const data = [
    {
        customer: "rupenp@cxosync.com",
        status: "PAID",
        currency: "USD",
        amount: "299 USD",
        share: "35 %",
        balance: "+ USD 104.65 (to pay to us)",
        date: "5 months ago",
    },
    {
        customer: "sontrez@gmail.com",
        status: "PAID",
        currency: "USD",
        amount: "299 USD",
        share: "35 %",
        balance: "+ USD 104.65 (to pay to us)",
        date: "5 months ago",
    },
    {
        customer: "angelica.pedraza@hidronor.cl",
        status: "PAID",
        currency: "USD",
        amount: "299 USD",
        share: "35 %",
        balance: "+ USD 104.65 (to pay to us)",
        date: "6 months ago",
    },
    {
        customer: "support@leadsplanet.co",
        status: "PAID",
        currency: "USD",
        amount: "2.99 USD",
        share: "35 %",
        balance: "+ USD 1.0465 (to pay to us)",
        date: "6 months ago",
    },
    {
        customer: "luke.goodwin@flexqube.com",
        status: "PAID",
        currency: "USD",
        amount: "99 USD",
        share: "35 %",
        balance: "+ USD 34.65 (to pay to us)",
        date: "6 months ago",
    },
];

const periods = [
    "2023-11",
    "2023-12",
    "2024-01",
    "2024-02",
    "2024-03",
    "2024-04",
    "2024-05",
];

const SalesTracked = () => {
    return (
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
            <div className="p-3">
                <div className="flex justify-between items-center pt-3 rounded-t-lg">
                    <div className="flex items-center gap-x-4">
                        <div className="text-white shrink-0 bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                            <FaMoneyCheckDollar size={20} />
                        </div>
                        <p className="text-white shrink-0">Sales Tracked</p>
                        <select className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                            {periods.map((period, index) => (
                                <option key={index} value={period} className="bg-bgBodyDark">
                                    Period of: {period}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button className="bg-primary text-white px-4 py-2 rounded hover:opacity-80">View my balance</button>
                </div>
                <div className="my-5">
                    <button className="bg-primary text-white px-4 py-2 rounded hover:opacity-80">Export sales refunds, disputes (.csv or .xls)</button>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead>
                            <tr className="text-left">
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    Customer
                                </th>
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    Status
                                </th>
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    Currency
                                </th>
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    Amount
                                </th>
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    % share
                                </th>
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    Balance
                                </th>
                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                    Date
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} className="border-t">
                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                        {item.customer}
                                    </td>
                                    <td className="font-normal text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                        <span className="flex items-center">
                                            <span className="text-green-500 bg-green-100 rounded-lg px-2 py-1">
                                                {item.status}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                        {item.currency}
                                    </td>
                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">{item.amount}</td>
                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">{item.share}</td>
                                    <td className="font-normal text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px] text-green-600">
                                        {item.balance}
                                    </td>
                                    <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">{item.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SalesTracked;
