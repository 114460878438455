import { validate } from "@constants/helper";
import { SET_RESELLER_INFO } from "@store/constants/action-types";
import React, { useRef, useState } from "react";
import { FaPaintRoller } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const StepFour = () => {
    const logoRef = useRef(null);
    const faviconRef = useRef(null);
    const dispatch = useDispatch();
    const logo = useSelector(store => store.domain?.resellerData?.logo)
    const favicon = useSelector(store => store.domain?.resellerData?.favicon)
    const showError = useSelector(store => store.domain?.showError)

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type !== "image/png") {
                toast.error("Please upload a PNG file.");
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                toast.error("Please upload a file smaller than 2MB.");
                return;
            }
        }
        dispatch({
            type: SET_RESELLER_INFO,
            payload: { [e.target.name]: file },
        });
    };

    return (
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
            <div className="border border-bgBodyDark rounded-lg pb-3">
                <div className="bg-bgBodyDark flex justify-between items-center px-5 py-3 rounded-t-lg">
                    <div className="flex items-center gap-x-4">
                        <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                            <FaPaintRoller size={20} />
                        </div>
                        <p className="text-white">Favicon & logo</p>
                        <a
                            href="https://canva.com"
                            target="_blank"
                            className="bg-primary text-white px-4 py-2 text-sm rounded-full hover:opacity-80"
                        >
                            Open Canva.com to design your logo and favicon
                        </a>
                    </div>
                    <div className="border-primary border-2 rounded bg-primary/50 px-4 py-2">
                        <p className="text-white">Step 4</p>
                    </div>
                </div>
                <div className="p-3 mt-3">
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-5 gap-y-3">
                        <div className="flex flex-col items-start gap-3">
                            <label htmlFor="logo_upload" className="text-white">
                                Upload a logo
                            </label>
                            <input
                                type="file"
                                ref={logoRef}
                                name="logo"
                                className="hidden"
                                id="logo_upload"
                                accept="image/png"
                                onChange={handleChange}
                            />
                            <div className="border-primary border flex justify-center items-center rounded-xl w-full border-dashed h-44">
                                {
                                    logo && 
                                    <img
                                        src={typeof logo === 'string' ? logo : URL.createObjectURL(logo)}
                                        alt="logoPrev"
                                        className="h-[75%] object-contain"
                                    />
                                }
                            </div>
                            {showError &&
                                <div className="text-white">
                                    {validate("logo", logo)}
                                </div>
                            }
                            <button
                                onClick={() => logoRef.current?.click()}
                                className="border-primary border text-white rounded-xl w-full border-dashed py-2 flex justify-center items-center gap-x-3"
                            >
                                <MdOutlineFileUpload size={20} />
                                Upload your logo
                            </button>
                            
                        </div>
                        <div className="flex flex-col items-start gap-3">
                            <label
                                htmlFor="favicon_upload"
                                className="text-white"
                            >
                                Upload a favicon
                            </label>
                            <input
                                type="file"
                                className="hidden"
                                name="favicon"
                                ref={faviconRef}
                                id="favicon_upload"
                                accept="image/png, image/jpeg"
                                onChange={handleChange}
                            />
                            <div className="border-primary border flex justify-center items-center rounded-xl w-full border-dashed h-44">
                                {
                                    favicon && 
                                    <img
                                        src={typeof logo === 'string' ? favicon : URL.createObjectURL(favicon)}
                                        alt="favicon"
                                        className="h-[75%] object-contain"
                                    />
                                }
                            </div>
                            {showError &&
                                <div className="text-white">
                                    {validate("favicon", favicon)}
                                </div>
                            }
                            <button
                                onClick={() => faviconRef.current?.click()}
                                className="border-primary border text-white rounded-xl w-full border-dashed py-2 flex justify-center items-center gap-x-3"
                            >
                                <MdOutlineFileUpload size={20} />
                                Upload your favicon
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepFour;
