import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, forwardRef, useImperativeHandle } from "react";
import { IoTrophy } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

const CommissionGridPopup = forwardRef(({ onClose }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
        },
        close() {
            setIsOpen(false);
        },
    }));

    const close = () => {
        setIsOpen(false);
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={close}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-bgBodyLight p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold text-center leading-6 text-white"
                                    >
                                        Revenue sharing per software
                                    </Dialog.Title>
                                    <div
                                        className="cursor-pointer text-white"
                                        onClick={close}
                                    >
                                        <RxCross2 size={20} />
                                    </div>
                                </div>
                                <div
                                    class="flex items-center p-4 mt-5 text-sm border border-blue-300 rounded-lg bg-blue-50"
                                    role="alert"
                                >
                                    <span class="sr-only">Info</span>
                                    <p>
                                        The more you sell, the less we will take commission on each sale. Discover below the grid of the revenue sharing per software.
                                    </p>
                                </div>
                                <div className="flex flex-col divide-y mt-5">
                                    <div className="flex items-center justify-between py-3">
                                        <div className="flex items-center gap-x-5">
                                            <div className="bg-gray-50 shrink-0 rounded p-3">
                                                <IoTrophy size={24} className="text-yellow-500"  />
                                            </div>
                                            <div>
                                                <p className="text-white/70 uppercase">From $500,00 - unlimited</p>
                                                <p className="text-white">Super Master SaaS partner</p>
                                                <p className="text-white">Since you started/created.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col space-y-2">
                                            <p className="uppercase text-white/70 text-sm">75 % for you</p>
                                            <div className="bg-green-500 rounded h-1" />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-3">
                                        <div className="flex items-center gap-x-5">
                                            <div className="bg-gray-50 shrink-0 rounded p-3">
                                                <IoTrophy size={24} className="text-yellow-500"  />
                                            </div>
                                            <div>
                                                <p className="text-white/70 uppercase">From $500,00 - unlimited</p>
                                                <p className="text-white">Super Master SaaS partner</p>
                                                <p className="text-white">Since you started/created.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col space-y-2">
                                            <p className="uppercase text-white/70 text-sm">75 % for you</p>
                                            <div className="bg-green-500 rounded h-1" />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-3">
                                        <div className="flex items-center gap-x-5">
                                            <div className="bg-gray-50 shrink-0 rounded p-3">
                                                <IoTrophy size={24} className="text-gray-400"  />
                                            </div>
                                            <div>
                                                <p className="text-white/70 uppercase">From $500,00 - unlimited</p>
                                                <p className="text-white">Super Master SaaS partner</p>
                                                <p className="text-white">Since you started/created.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col space-y-2">
                                            <p className="uppercase text-white/70 text-sm">75 % for you</p>
                                            <div className="bg-green-500 rounded h-1" />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between py-3">
                                        <div className="flex items-center gap-x-5">
                                            <div className="bg-gray-50 shrink-0 rounded p-3">
                                                <IoTrophy size={24} className="text-gray-400"  />
                                            </div>
                                            <div>
                                                <p className="text-white/70 uppercase">From $500,00 - unlimited</p>
                                                <p className="text-white">Super Master SaaS partner</p>
                                                <p className="text-white">Since you started/created.</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-col space-y-2">
                                            <p className="uppercase text-white/70 text-sm">75 % for you</p>
                                            <div className="bg-green-500 rounded h-1" />
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
});

export default CommissionGridPopup;
