export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const BRAND_EMAIL_INIT = "BRAND_EMAIL_INIT";
export const BRAND_EMAIL_FINISH = "BRAND_EMAIL_FINISH";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_CATEGORIES_FAILURE";
export const HANDLE_CHANGE_SUCCESS = "HANDLE_CHANGE_SUCCESS";
export const HANDLE_SELECT_CHANGE_SUCCESS = "HANDLE_SELECT_CHANGE_SUCCESS";
export const HANDLE_REGISTER_SUCCESS = "HANDLE_REGISTER_SUCCESS";
export const HANDLE_VALIDATION_ERRORS = "HANDLE_VALIDATION_ERRORS";
export const HANDLE_REMOVE_VALIDATION_ERRORS =
  "HANDLE_REMOVE_VALIDATION_ERRORS";
export const HANDLE_ON_DROP_FILE = "HANDLE_ON_DROP_FILE";
export const HANDLE_ON_DROP_COVER = "HANDLE_ON_DROP_COVER";
export const REST_RESPONSE_STATUS = "REST_RESPONSE_STATUS";
export const HANDLE_SELECT_VOUCHER_TYPE = "HANDLE_SELECT_VOUCHER_TYPE";
export const HANDLE_SELECT_VOUCHER_FORMATE = "HANDLE_SELECT_VOUCHER_FORMATE";
export const HANDLE_PRODUCT_ADD_SUCCESS = "HANDLE_PRODUCT_ADD_SUCCESS";
export const HANDLE_PRODUCT_ADD_FAILURE = "HANDLE_PRODUCT_ADD_FAILURE";
export const HANDLE_VOUCHER_ADD_SUCCESS = "HANDLE_VOUCHER_ADD_SUCCESS";
export const HANDLE_VOUCHER_ADD_FAILURE = "HANDLE_VOUCHER_ADD_FAILURE";
export const HANDLE_FETCH_OFFER_SUCCESS = "HANDLE_FETCH_OFFER_SUCCESS";
export const HANDLE_FETCH_OFFER_FAILURE = "HANDLE_FETCH_OFFER_FAILURE";
export const HANDLE_GOOGLE_ANALYTICS = "HANDLE_GOOGLE_ANALYTICS";
export const HANDLE_GOOGLE_ANALYTICS_ACTIVITY =
  "HANDLE_GOOGLE_ANALYTICS_ACTIVITY";
export const HANDLE_PRODUCT_UPDATE_SUCCESS = "HANDLE_PRODUCT_UPDATE_SUCCESS";
export const HANDLE_PRODUCT_UPDATE_FAILURE = "HANDLE_PRODUCT_UPDATE_FAILURE";
export const HANDLE_BRAND_GMAIL_TOKEN_SUCCESS =
  "HANDLE_BRAND_GMAIL_TOKEN_SUCCESS";
export const HANDEL_BRAND_PROFILE_SETTING_SUCCESS =
  "HANDEL_BRAND_PROFILE_SETTING_SUCCESS";
export const HANDEL_BRAND_PROFILE_SETTING_FAILURE =
  "HANDEL_BRAND_PROFILE_SETTING_FAILURE";
export const HANDEL_ACTIVE_MODAL_SHOW = "HANDEL_ACTIVE_MODAL_SHOW";
export const HANDLE_UPDATE_AVATAR_SUCCESS = "HANDLE_UPDATE_AVATAR_SUCCESS";
export const HANDLE_UPDATE_AVATAR_FAILURE = "HANDLE_UPDATE_AVATAR_FAILURE";
export const HANDLE_UPDATE_PROFILE_SUCCESS = "HANDLE_UPDATE_PROFILE_SUCCESS";
export const HANDLE_UPDATE_PROFILE_FAILURE = "HANDLE_UPDATE_PROFILE_FAILURE";
export const HANDLE_ACCOUNT_SETTING_SUCCESS = "HANDLE_ACCOUNT_SETTING_SUCCESS";
export const HANDLE_ACCOUNT_SETTING_FAILURE = "HANDLE_ACCOUNT_SETTING_FAILURE";
export const HANDLE_EMAIL_UPDATE_SUCCESS = "HANDLE_EMAIL_UPDATE_SUCCESS";
export const HANDLE_EMAIL_UPDATE_FAILURE = "HANDLE_EMAIL_UPDATE_FAILURE";
export const EMAIL_CALL_INIT = "EMAIL_CALL_INIT";
export const EMAIL_CALL_FINISH = "EMAIL_CALL_FINISH";
export const HANDLE_USER_NAME_UPDATE_SUCCESS =
  "HANDLE_USER_NAME_UPDATE_SUCCESS";
export const HANDLE_USER_NAME_UPDATE_FAILURE =
  "HANDLE_USER_NAME_UPDATE_FAILURE";
export const HANDLE_PASSWORD_UPDATE_SUCCESS = "HANDLE_PASSWORD_UPDATE_SUCCESS";
export const HANDLE_PASSWORD_UPDATE_FAILURE = "HANDLE_PASSWORD_UPDATE_FAILURE";
export const HANDLE_FETCH_CREDIT_CARDS_SUCCESS =
  "HANDLE_FETCH_CREDIT_CARDS_SUCCESS";
export const HANDLE_FETCH_CREDIT_CARDS_FAILURE =
  "HANDLE_FETCH_CREDIT_CARDS_FAILURE";
export const HANDLE_ADD_CREDIT_CARD_SUCCESS = "HANDLE_ADD_CREDIT_CARD_SUCCESS";
export const HANDLE_ADD_CREDIT_CARD_FAILURE = "HANDLE_ADD_CREDIT_CARD_FAILURE";
export const HANDLE_REMOVE_CREDIT_CARD_SUCCESS =
  "HANDLE_REMOVE_CREDIT_CARD_SUCCESS";
export const HANDLE_REMOVE_CREDIT_CARD_FAILURE =
  "HANDLE_REMOVE_CREDIT_CARD_FAILURE";
export const HANDLE_UPDATE_BILLING_ADDRESS_SUCCESS =
  "HANDLE_UPDATE_BILLING_ADDRESS_SUCCESS";
export const HANDLE_UPDATE_BILLING_ADDRESS_FAILURE =
  "HANDLE_UPDATE_BILLING_ADDRESS_FAILURE";
export const HANDLE_REMOVE_PRODUCT_SUCCESS = "HANDLE_REMOVE_PRODUCT_SUCCESS";
export const HANDLE_REMOVE_PRODUCT_FAILURE = "HANDLE_REMOVE_PRODUCT_FAILURE";
export const HANDLE_RETIRE_PRODUCT_SUCCESS = "HANDLE_RETIRE_PRODUCT_SUCCESS";
export const HANDLE_RETIRE_PRODUCT_FAILURE = "HANDLE_RETIRE_PRODUCT_FAILURE";
export const HANDLE_FETCH_ALL_PRODUCTSS_SUCCESS =
  "HANDLE_FETCH_ALL_PRODUCTSS_SUCCESS";
export const HANDLE_FETCH_ALL_PRODUCTSS_FAILURE =
  "HANDLE_FETCH_ALL_PRODUCTSS_FAILURE";
export const FETCH_INFLUENCERS_SUCCESS = "FETCH_INFLUENCERS_SUCCESS";
export const FETCH_INFLUENCERS_FAILURE = "FETCH_INFLUENCERS_FAILURE";
export const FETCH_PLATFORMS_SUCCESS = "FETCH_PLATFORMS_SUCCESS";
export const FETCH_PLATFORMS_FAILURE = "FETCH_PLATFORMS_FAILURE";
export const FETCH_COUNTRIES_SUCCESS = "FETCH_COUNTRIES_SUCCESS";
export const FETCH_COUNTRIES_FAILURE = "FETCH_COUNTRIES_FAILURE";
export const HANDLE_CHANGE_PLATFORM_SUCCESS = "HANDLE_CHANGE_PLATFORM_SUCCESS";
export const HANDLE_CHANGE_COUNTRY_SUCCESS = "HANDLE_CHANGE_COUNTRY_SUCCESS";
export const HANDLE_CHANGE_CATEOGRY_SUCCESS = "HANDLE_CHANGE_CATEOGRY_SUCCESS";
export const HANDLE_CHANGE_GENDER_SUCCESS = "HANDLE_CHANGE_GENDER_SUCCESS";
export const HANDLE_CHANGE_AGE_SUCCESS = "HANDLE_CHANGE_AGE_SUCCESS";
export const HANDLE_CHANGE_SEARCH_SUCCESS = "HANDLE_CHANGE_SEARCH_SUCCESS";
export const HANDLE_CHANGE_SORT_SUCCESS = "HANDLE_CHANGE_SORT_SUCCESS";
export const HANDLE_CHANGE_FILTER_SUCCESS = "HANDLE_CHANGE_FILTER_SUCCESS";
export const HANDLE_CHANGE_NUMBER_VOUCHER_SUCCESS =
  "HANDLE_CHANGE_NUMBER_VOUCHER_SUCCESS";
export const HANDLE_CHANGE_NUMBER_VOUCHER_LINK_SUCCESS =
  "HANDLE_CHANGE_NUMBER_VOUCHER_LINK_SUCCESS";
export const HANDLE_CHANGE_CODE_VOUCHER_VALUE =
  "HANDLE_CHANGE_CODE_VOUCHER_VALUE";
export const HANDLE_CHANGE_LINK_VOUCHER_VALUE =
  "HANDLE_CHANGE_LINK_VOUCHER_VALUE";
export const HANDLE_REMOVE_RETIRE_PRODUCT_SUCCESS =
  "HANDLE_REMOVE_RETIRE_PRODUCT_FAILURE";
export const HANDLE_REMOVE_RETIRE_PRODUCT_FAILURE =
  "HANDLE_REMOVE_RETIRE_PRODUCT_FAILURE";
export const HANDLE_SET_NEW_CAMPAIGN_SUCCESS =
  "HANDLE_SET_NEW_CAMPAIGN_SUCCESS";
export const HANDLE_SET_NEW_CAMPAIGN_FAILURE =
  "HANDLE_SET_NEW_CAMPAIGN_FAILURE";

export const HANDLE_FETCH_CAMPAIGN_SUCCESS = "HANDLE_FETCH_CAMPAIGN_SUCCESS";
export const HANDLE_FETCH_CAMPAIGN_FAILURE = "HANDLE_FETCH_CAMPAIGN_FAILURE";
export const HANDLE_SELECT_TYPE_SUCCESS = "HANDLE_SELECT_TYPE_SUCCESS";
export const HANDLE_CAMPAIGN_BASIC_CHANGE_SUCCESS =
  "HANDLE_CAMPAIGN_BASIC_CHANGE_SUCCESS";
export const HANDLE_SELECT_POST_TYPE = "HANDLE_SELECT_POST_TYPE";
export const HANDLE_CHANGE_SINGLE_CONTENT = "HANDLE_CHANGE_SINGLE_CONTENT";
export const HANDLE_CHANGE_MULTI_CONTENT = "HANDLE_CHANGE_MULTI_CONTENT";
export const HANDLE_CHANGE_CAMPAIGN_BRIEF = "HANDLE_CHANGE_CAMPAIGN_BRIEF";
export const DATE_CHANGE_HANDLER = "DATE_CHANGE_HANDLER";
export const ADD_SELECTED_INFLUENCER = "ADD_SELECTED_INFLUENCER";
export const REMOVE_SELECTED_INFLUENCER = "REMOVE_SELECTED_INFLUENCER";
export const REMOVE_ALL_SELECTED_INFLUENCER = "REMOVE_ALL_SELECTED_INFLUENCER";
export const BUDGET_SERVICE_FEE_CHANGE = "BUDGET_SERVICE_FEE_CHANGE";
export const HANDLE_FETCH_RANGES = "HANDLE_FETCH_RANGES";
export const HANDLE_PAY_PER_CLICK = "HANDLE_PAY_PER_CLICK";
export const HANDLE_PRICE_PER_INFLUENCER = "HANDLE_PRICE_PER_INFLUENCER";
export const HANDLE_PLATFORM_CHANGE = "HANDLE_PLATFORM_CHANGE";
export const HANDLE_PRODUCT_PER_INFLUENCER = "HANDLE_PRODUCT_PER_INFLUENCER";
export const HANDLE_IMAGE_OPTION = "HANDLE_IMAGE_OPTION";
export const HANDLE_SUBMIT_OVERVIEW_CHANGE = "HANDLE_SUBMIT_OVERVIEW_CHANGE";
export const HANDLE_CREDIT_CARD_ERRORS = "HANDLE_CREDIT_CARD_ERRORS";
export const HANDLE_MODIFY_CHANGE = "HANDLE_MODIFY_CHANGE";
export const HANDLE_CANCEL_REDIRECT = "HANDLE_CANCEL_REDIRECT";
export const FETCH_INFLUENCER_CAMPAIGNS_SUCCESS =
  "FETCH_INFLUENCER_CAMPAIGNS_SUCCESS";
export const FETCH_INFLUENCER_CAMPAIGNS_FAILURE =
  "FETCH_INFLUENCER_CAMPAIGNS_FAILURE";
export const FETCH_INFLUENCER_FIND_CAMPAIGNS_SUCCESS =
  "FETCH_INFLUENCER_FIND_CAMPAIGNS_SUCCESS";
export const FETCH_INFLUENCER_FIND_CAMPAIGNS_FAILURE =
  "FETCH_INFLUENCER_FIND_CAMPAIGNS_FAILURE";
export const FETCH_INFLUENCER_PROFILE_INIT = "FETCH_INFLUENCER_PROFILE_INIT";
export const FETCH_INFLUENCER_PROFILE_FINSH = "FETCH_INFLUENCER_PROFILE_FINSH";
export const FETCH_INFLUENCER_PROFILE_SUCCESS =
  "FETCH_INFLUENCER_PROFILE_SUCCESS";
export const FETCH_INFLUENCER_PROFILE_FAILURE =
  "FETCH_INFLUENCER_PROFILE_FAILURE";
export const FACEBOOK_LOGIN_BUTTON = "FACEBOOK_LOGIN_BUTTON";
export const HANDLE_FETCH_PLATFORMS_SUCCESS = "HANDLE_FETCH_PLATFORMS_SUCCESS";
export const HANDLE_FETCH_PLATFORMS_FAILURE = "HANDLE_FETCH_PLATFORMS_FAILURE";
export const HANDLE_CONNECT_INSTAGRAM = "HANDLE_CONNECT_INSTAGRAM";
export const HANDLE_CONNECT_INSTAGRAM_SUBMIT_SUCCESS =
  "HANDLE_CONNECT_INSTAGRAM_SUBMIT_SUCCESS";
export const HANDLE_CONNECT_INSTAGRAM_SUBMIT_FAILURE =
  "HANDLE_CONNECT_INSTAGRAM_SUBMIT_FAILURE";
export const HANDLE_CONNECT_INIT = "HANDLE_CONNECT_INIT";
export const HANDLE_CONNECT_FINISH = "HANDLE_CONNECT_FINISH";
export const HANDLE_SELECT_PAGE = "HANDLE_SELECT_PAGE";
export const AJAX_CALL_INIT = "AJAX_CALL_INIT";
export const AJAX_CALL_FINSH = "AJAX_CALL_FINSH";
export const HANDLE_SAVE_CHANGE_PLATFROM_SUCCESS =
  "HANDLE_SAVE_CHANGE_PLATFROM_SUCCESS";
export const HANDLE_SAVE_CHANGE_PLATFROM_FAILURE =
  "HANDLE_SAVE_CHANGE_PLATFROM_FAILURE";
export const HANDLE_DISCONNECT_PLATFORM_SUCCESS =
  "HANDLE_DISCONNECT_PLATFORM_SUCCESS";
export const HANDLE_DISCONNECT_PLATFORM_FAILURE =
  "HANDLE_DISCONNECT_PLATFORM_FAILURE";

export const HANDEL_BRAND_BASIC_INFO_SETTIGNS_SUCCESS =
  "HANDEL_BRAND_BASIC_INFO_SETTIGNS_SUCCESS";
export const HANDEL_BRAND_BASIC_INFO_SETTIGNS_FAILURE =
  "HANDEL_BRAND_BASIC_INFO_SETTIGNS_FAILURE";
export const HANDEL_INFLUENCER_BASIC_INFO_SETTIGNS_SUCCESS =
  "HANDEL_INFLUENCER_BASIC_INFO_SETTIGNS_SUCCESS";
export const HANDEL_INFLUENCER_BASIC_INFO_SETTIGNS_FAILURE =
  "HANDEL_INFLUENCER_BASIC_INFO_SETTIGNS_FAILURE";
export const HANDLE_REGISTER_SUBMIT_SUCEESS = "HANDLE_REGISTER_SUBMIT_SUCEESS";
export const HANDLE_REGISTER_SUBMIT_FAILURE = "HANDLE_REGISTER_SUBMIT_FAILURE";
export const HANDLE_FORGET_PASSWORD_SUBMIT_SUCEESS =
  "HANDLE_FORGET_PASSWORD_SUBMIT_SUCEESS";
export const HANDLE_FORGET_PASSWORD_SUBMIT_FAILURE =
  "HANDLE_FORGET_PASSWORD_SUBMIT_FAILURE";
export const HANDLE_RESET_PASSWORD_VIEW_SUCEESS =
  "HANDLE_RESET_PASSWORD_VIEW_SUCEESS";
export const HANDLE_RESET_PASSWORD_VIEW_FAILURE =
  "HANDLE_RESET_PASSWORD_VIEW_FAILURE";
export const HANDLE_RESET_PASSWORD_SUBMIT_SUCEESS =
  "HANDLE_RESET_PASSWORD_SUBMIT_SUCEESS";
export const HANDLE_RESET_PASSWORD_SUBMIT_FAILURE =
  "HANDLE_RESET_PASSWORD_SUBMIT_FAILURE";
export const FETCH_BRAND_PROFILE_SUCCESS = "FETCH_BRAND_PROFILE_SUCCESS";
export const FETCH_BRAND_PROFILE_FAILURE = "FETCH_BRAND_PROFILE_FAILURE";
export const HANDLE_CLOSE_BRAND_PROFILE = "HANDLE_CLOSE_BRAND_PROFILE";
export const INFLUENCER_BOOKING_CAMPAIGN_OVERVIEW_SUCCESS =
  "INFLUENCER_BOOKING_CAMPAIGN_OVERVIEW_SUCCESS";
export const INFLUENCER_BOOKING_CAMPAIGN_OVERVIEW_FAILURE =
  "INFLUENCER_BOOKING_CAMPAIGN_OVERVIEW_FAILURE";
export const HANDLE_INFLUENCER_BOOKING_REQUEST_SUCCESS =
  "HANDLE_INFLUENCER_BOOKING_REQUEST_SUCCESS";
export const HANDLE_INFLUENCER_BOOKING_REQUEST_FAILURE =
  "HANDLE_INFLUENCER_BOOKING_REQUEST_FAILURE";
export const FETCH_WAITING_INFLUENCER_CAMPAIGNS_SUCCESS =
  "FETCH_WAITING_INFLUENCER_CAMPAIGNS_SUCCESS";
export const FETCH_WAITING_INFLUENCER_CAMPAIGNS_FAILURE =
  "FETCH_WAITING_INFLUENCER_CAMPAIGNS_FAILURE";
export const BRAND_BOOKING_CAMPAIGN_INFLUENCERS_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_INFLUENCERS_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_INFLUENCERS_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_INFLUENCERS_FAILURE";
export const BRAND_BOOKING_CAMPAIGN_BRIEF_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_BRIEF_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_BRIEF_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_BRIEF_FAILURE";
export const HANDEL_ACCEPT_MESSAGE_OPTIONAL = "HANDEL_ACCEPT_MESSAGE_OPTIONAL";
export const HANDEL_REJECT_MESSAGE_OPTIONAL = "HANDEL_REJECT_MESSAGE_OPTIONAL";
export const BRAND_BOOKING_CAMPAIGN_ACCEPT_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_ACCEPT_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_ACCEPT_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_ACCEPT_FAILURE";
export const FETCH_TODO_INFLUENCER_CAMPAIGNS_SUCCESS =
  "FETCH_TODO_INFLUENCER_CAMPAIGNS_SUCCESS";
export const FETCH_TODO_INFLUENCER_CAMPAIGNS_FAILURE =
  "FETCH_TODO_INFLUENCER_CAMPAIGNS_FAILURE ";
export const FETCH_CLOSED_INFLUENCER_CAMPAIGNS_SUCCESS =
  "FETCH_CLOSED_INFLUENCER_CAMPAIGNS_SUCCESS ";
export const FETCH_CLOSED_INFLUENCER_CAMPAIGNS_FAILURE =
  "FETCH_CLOSED_INFLUENCER_CAMPAIGNS_FAILURE ";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_SUCCESS ";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_FAILURE ";
export const BRAND_BOOKING_CAMPAIGN_REJECT_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_REJECT_SUCCESS ";
export const BRAND_BOOKING_CAMPAIGN_REJECT_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_REJECT_FAILURE ";
export const INFLUENCER_BOOKING_CAMPAIGN_TASK =
  "INFLUENCER_BOOKING_CAMPAIGN_TASK ";
export const INFLUENCER_BOOKING_CAMPAIGN_PAYMENT_SUCCESS =
  "INFLUENCER_BOOKING_CAMPAIGN_PAYMENT_SUCCESS ";
export const INFLUENCER_BOOKING_CAMPAIGN_PAYMENT_FAILURE =
  "INFLUENCER_BOOKING_CAMPAIGN_PAYMENT_FAILURE ";
export const INFLUENCER_BOOKING_CAMPAIGN_CONTENT_SUCCESS =
  "INFLUENCER_BOOKING_CAMPAIGN_CONTENT_SUCCESS ";
export const INFLUENCER_BOOKING_CAMPAIGN_CONTENT_FAILURE =
  "INFLUENCER_BOOKING_CAMPAIGN_CONTENT_FAILURE ";
export const SWITCH_TO_FAST_PAYMENT_SUCCESS = "SWITCH_TO_FAST_PAYMENT_SUCCESS ";
export const SWITCH_TO_FAST_PAYMENT_FAILURE = "SWITCH_TO_FAST_PAYMENT_FAILURE ";
export const CAMPAIGN_CONTENT_UPLOAD_STORY_SUCCESS =
  "CAMPAIGN_CONTENT_UPLOAD_STORY_SUCCESS  ";
export const CAMPAIGN_CONTENT_UPLOAD_STORY_FAILURE =
  "CAMPAIGN_CONTENT_UPLOAD_STORY_FAILURE  ";
export const CAMPAIGN_CONTENT_UPLOAD_STORY_INSIGHT_SUCCESS =
  "CAMPAIGN_CONTENT_UPLOAD_STORY_INSIGHT_SUCCESS  ";
export const CAMPAIGN_CONTENT_UPLOAD_STORY_INSIGHT_FAILURE =
  "CAMPAIGN_CONTENT_UPLOAD_STORY_INSIGHT_FAILURE  ";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_SUCCESS  ";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_FAILURE  ";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_REQUEST_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_REQUEST_SUCCESS  ";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_REQUEST_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_REQUEST_FAILURE  ";
export const HANDLE_INFLUENCER_CANCEL_REQUEST_SUCCESS =
  "HANDLE_INFLUENCER_CANCEL_REQUEST_SUCCESS  ";
export const HANDLE_INFLUENCER_CANCEL_REQUEST_FAILURE =
  "HANDLE_INFLUENCER_CANCEL_REQUEST_FAILURE  ";
export const FETCH_ABORT_REASONS_SUCCESS = "FETCH_ABORT_REASONS_SUCCESS  ";
export const FETCH_ABORT_REASONS_FAILURE = "FETCH_ABORT_REASONS_FAILURE  ";
export const HANDLE_INFLUENCER_ABORT_REQUEST_SUCCESS =
  "HANDLE_INFLUENCER_ABORT_REQUEST_SUCCESS  ";
export const HANDLE_INFLUENCER_ABORT_REQUEST_FAILURE =
  "HANDLE_INFLUENCER_ABORT_REQUEST_FAILURE  ";
export const BRAND_BOOKING_CAMPAIGN_ACTIVITES_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_ACTIVITES_SUCCESS  ";
export const BRAND_BOOKING_CAMPAIGN_ACTIVITES_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_ACTIVITES_FAILURE  ";
export const HANDLE_INFLUENCER_WORK_DONE_REQUEST_SUCCESS =
  "HANDLE_INFLUENCER_WORK_DONE_REQUEST_SUCCESS  ";
export const HANDLE_INFLUENCER_WORK_DONE_REQUEST_FAILURE =
  "HANDLE_INFLUENCER_WORK_DONE_REQUEST_FAILURE  ";
export const BRAND_BOOKING_CAMPAIGN_MESSAGES_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_MESSAGES_SUCCESS  ";
export const BRAND_BOOKING_CAMPAIGN_MESSAGES_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_MESSAGES_FAILURE  ";
export const BRAND_BOOKING_CAMPAIGN_CHAT_USERS_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CHAT_USERS_SUCCESS  ";
export const BRAND_BOOKING_CAMPAIGN_CHAT_USERS_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CHAT_USERS_FAILURE  ";
export const FETCH_CHAT_USER_MESSAGES_SUCCESS =
  "FETCH_CHAT_USER_MESSAGES_SUCCESS  ";
export const FETCH_CHAT_USER_MESSAGES_FAILURE =
  "FETCH_CHAT_USER_MESSAGES_FAILURE  ";
export const BRAND_SEND_MESSAGE_SUCCESS = "BRAND_SEND_MESSAGE_SUCCESS  ";
export const BRAND_SEND_MESSAGE_FAILURE = "BRAND_SEND_MESSAGE_FAILURE  ";
export const INFLUENCER_BOOKING_CAMPAIGN_MESSAGES_SUCCESS =
  "INFLUENCER_BOOKING_CAMPAIGN_MESSAGES_SUCCESS  ";
export const INFLUENCER_BOOKING_CAMPAIGN_MESSAGES_FAILURE =
  "INFLUENCER_BOOKING_CAMPAIGN_MESSAGES_FAILURE  ";
export const INFLUENCER_SEND_MESSAGE_SUCCESS =
  "INFLUENCER_SEND_MESSAGE_SUCCESS  ";
export const INFLUENCER_SEND_MESSAGE_FAILURE =
  "INFLUENCER_SEND_MESSAGE_FAILURE  ";
export const BRAND_SEND_ATTACHMENT_SUCCESS = "BRAND_SEND_ATTACHMENT_SUCCESS  ";
export const BRAND_SEND_ATTACHMENT_FAILURE = "BRAND_SEND_ATTACHMENT_FAILURE  ";
export const INFLUENCER_SEND_ATTACHMENT_SUCCESS =
  "INFLUENCER_SEND_ATTACHMENT_SUCCESS  ";
export const INFLUENCER_SEND_ATTACHMENT_FAILURE =
  "INFLUENCER_SEND_ATTACHMENT_FAILURE  ";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS  ";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE  ";
export const FETCH_DASHBOARD_CAMPAIGNS_SUCCESS =
  "FETCH_DASHBOARD_CAMPAIGNS_SUCCESS";
export const FETCH_DASHBOARD_CAMPAIGNS_FAILURE =
  "FETCH_DASHBOARD_CAMPAIGNS_FAILURE";
export const CURRENT_LOGGED_IN_USER_SUCCESS = "CURRENT_LOGGED_IN_USER_SUCCESS";
export const CURRENT_LOGGED_IN_USER_FAILURE = "CURRENT_LOGGED_IN_USER_FAILURE";
export const INFLUENCER_BOOKING_CAMPAIGN_ACCEPT_SUCCESS =
  "INFLUENCER_BOOKING_CAMPAIGN_ACCEPT_SUCCESS";
export const INFLUENCER_BOOKING_CAMPAIGN_ACCEPT_FAILURE =
  "INFLUENCER_BOOKING_CAMPAIGN_ACCEPT_FAILURE";
export const INFLUENCER_BOOKING_CAMPAIGN_REJECT_SUCCESS =
  "INFLUENCER_BOOKING_CAMPAIGN_REJECT_SUCCESS";
export const INFLUENCER_BOOKING_CAMPAIGN_REJECT_FAILURE =
  "INFLUENCER_BOOKING_CAMPAIGN_REJECT_FAILURE";
export const FETCH_GOALS_SUCCESS = "FETCH_GOALS_SUCCESS";
export const FETCH_GOALS_FAILURE = "FETCH_GOALS_FAILURE";
export const BRAND_BOOKING_CAMPAIGN_OVERVIEW_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_OVERVIEW_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_OVERVIEW_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_OVERVIEW_FAILURE";
export const HANDLE_INFLUENCER_PREVIEW_REQUEST_SUCCESS =
  "HANDLE_INFLUENCER_PREVIEW_REQUEST_SUCCESS";
export const HANDLE_INFLUENCER_PREVIEW_REQUEST_FAILURE =
  "HANDLE_INFLUENCER_PREVIEW_REQUEST_FAILURE";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_PREVIEW_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_PREVIEW_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_PREVIEW_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_PREVIEW_FAILURE";
export const INFLUENCER_INSTAGRAM_FEEDS_SUCCESS =
  "INFLUENCER_INSTAGRAM_FEEDS_SUCCESS";
export const INFLUENCER_INSTAGRAM_FEEDS_FAILURE =
  "INFLUENCER_INSTAGRAM_FEEDS_FAILURE";
export const HANDLE_SELECT_POST_SUCCESS = "HANDLE_SELECT_POST_SUCCESS";
export const INFLUENCER_SELECTED_FEED_POST_SUCCESS =
  "INFLUENCER_SELECTED_FEED_POST_SUCCESS";
export const INFLUENCER_SELECTED_FEED_POST_FAILURE =
  "INFLUENCER_SELECTED_FEED_POST_FAILURE";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_POST_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_POST_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_POST_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_APPROVE_POST_FAILURE";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_POST_REQUEST_SUCCESS =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_POST_REQUEST_SUCCESS";
export const BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_POST_REQUEST_FAILURE =
  "BRAND_BOOKING_CAMPAIGN_CONTENT_NEW_POST_REQUEST_FAILURE";
export const HANDLE_SELECT_CATEGORY_SUCCESS = "HANDLE_SELECT_CATEGORY_SUCCESS";
export const HANDLE_SELECT_GOAL_SUCCESS = "HANDLE_SELECT_GOAL_SUCCESS";
export const FETCH_USER_CATEGORIES_SUCCESS = "FETCH_USER_CATEGORIES_SUCCESS";
export const FETCH_USER_CATEGORIES_FAILURE = "FETCH_USER_CATEGORIES_FAILURE";
export const HANDLE_INFLUENCER_REGISTRATION_FINSH_SUCCESS =
  "HANDLE_INFLUENCER_REGISTRATION_FINSH_SUCCESS";
export const HANDLE_INFLUENCER_REGISTRATION_FINSH_FAILURE =
  "HANDLE_INFLUENCER_REGISTRATION_FINSH_FAILURE";
export const HANDLE_REFRESH_INFLEUNCER_PROFILE_SUCCESS =
  "HANDLE_REFRESH_INFLEUNCER_PROFILE_SUCCESS";
export const HANDLE_REFRESH_INFLEUNCER_PROFILE_FAILURE =
  "HANDLE_REFRESH_INFLEUNCER_PROFILE_FAILURE";
export const HANDLE_SET_UP_NEW_CAMPAIGN_SUCCESS =
  "HANDLE_SET_UP_NEW_CAMPAIGN_SUCCESS";
export const HANDLE_SET_UP_NEW_CAMPAIGN_FAILURE =
  "HANDLE_SET_UP_NEW_CAMPAIGN_FAILURE";
export const HANDLE_SELECT_INFLUENCER_PAYMENT_TYPE_SUCCESS =
  "HANDLE_SELECT_INFLUENCER_PAYMENT_TYPE_SUCCESS";
export const HANDLE_SELECT_CONTENT_PAYMENT_TYPE_SUCCESS =
  "HANDLE_SELECT_CONTENT_PAYMENT_TYPE_SUCCESS";
export const HANDLE_PRICE_PER_CONTENT_SUCCESS =
  "HANDLE_PRICE_PER_CONTENT_SUCCESS";
export const HANDLE_EMPTY_CAMPAIGN_ID_SUCCESS =
  "HANDLE_EMPTY_CAMPAIGN_ID_SUCCESS";
export const FETCH_INFLUENCER_TIKTOK_PROFILE_SUCCESS =
  "FETCH_INFLUENCER_TIKTOK_PROFILE_SUCCESS";
export const FETCH_INFLUENCER_TIKTOK_PROFILE_FAILURE =
  "FETCH_INFLUENCER_TIKTOK_PROFILE_FAILURE";
export const FETCH_LANGS_SUCCESS = "FETCH_LANGS_SUCCESS";
export const FETCH_LANGS_FAILURE = "FETCH_LANGS_FAILURE";
export const USER_SELECTED_CATEGORIES_SUCCESS =
  "USER_SELECTED_CATEGORIES_SUCCESS";
export const USER_SELECTED_CATEGORIES_FAILURE =
  "USER_SELECTED_CATEGORIES_FAILURE";
export const HANDLE_SAVE_CHANGE_CATEGORIES_SUCCESS =
  "HANDLE_SAVE_CHANGE_CATEGORIES_SUCCESS";
export const HANDLE_SAVE_CHANGE_CATEGORIES_FAILURE =
  "HANDLE_SAVE_CHANGE_CATEGORIES_FAILURE";
export const HANDLE_CHANGE_CONNECT_BANK_SUCCESS =
  "HANDLE_CHANGE_CONNECT_BANK_SUCCESS";
export const HANDLE_SELECT_BANK_COUNTRY_SUCCESS =
  "HANDLE_SELECT_BANK_COUNTRY_SUCCESS";
export const HANDLE_ADD_BANK_ACCOUNT_SUCCESS =
  "HANDLE_ADD_BANK_ACCOUNT_SUCCESS";
export const HANDLE_ADD_BANK_ACCOUNT_FAILURE =
  "HANDLE_ADD_BANK_ACCOUNT_FAILURE";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_FAILURE = "FETCH_CURRENCIES_FAILURE";
export const HANDLE_SELECT_CURRENCY_SUCCESS = "HANDLE_SELECT_CURRENCY_SUCCESS";
export const USER_ATTACHED_ACCOUNTS_SUCCESS = "USER_ATTACHED_ACCOUNTS_SUCCESS";
export const USER_ATTACHED_ACCOUNTS_FAILURE = "USER_ATTACHED_ACCOUNTS_FAILURE";
export const HANDLE_REMOVE_BANK_ACCOUNT_SUCCESS =
  "HANDLE_REMOVE_BANK_ACCOUNT_SUCCESS";
export const HANDLE_REMOVE_BANK_ACCOUNT_FAILURE =
  "HANDLE_REMOVE_BANK_ACCOUNT_FAILURE";
export const HANDLE_SWTICH_PAYMENT_METHOD_SUCCESS =
  "HANDLE_SWTICH_PAYMENT_METHOD_SUCCESS";
export const HANDLE_SWTICH_PAYMENT_METHOD_FAILURE =
  "HANDLE_SWTICH_PAYMENT_METHOD_FAILURE";
export const HANDLE_BRAND_REFRESH_INFLEUNCER_PROFILE_SUCCESS =
  "HANDLE_BRAND_REFRESH_INFLEUNCER_PROFILE_SUCCESS";
export const HANDLE_BRAND_REFRESH_INFLEUNCER_PROFILE_FAILURE =
  "HANDLE_BRAND_REFRESH_INFLEUNCER_PROFILE_FAILURE";
export const HANDLE_USER_GENERAL_NOTIFICATIONS_SUCCESS =
  "HANDLE_USER_GENERAL_NOTIFICATIONS_SUCCESS";
export const HANDLE_USER_GENERAL_NOTIFICATIONS_FAILURE =
  "HANDLE_USER_GENERAL_NOTIFICATIONS_FAILURE";
export const HANDLE_USER_CAMPAIGN_NOTIFICATIONS_SUCCESS =
  "HANDLE_USER_CAMPAIGN_NOTIFICATIONS_SUCCESS";
export const HANDLE_USER_CAMPAIGN_NOTIFICATIONS_FAILURE =
  "HANDLE_USER_CAMPAIGN_NOTIFICATIONS_FAILURE";
export const HANDLE_SAVE_CHANGES_NOTIFICATIONS_SUCCESS =
  "HANDLE_SAVE_CHANGES_NOTIFICATIONS_SUCCESS";
export const HANDLE_SAVE_CHANGES_NOTIFICATIONS_FAILURE =
  "HANDLE_SAVE_CHANGES_NOTIFICATIONS_FAILURE";
export const AJAX_CALL_NOTIFICATION_INIT = "AJAX_CALL_NOTIFICATION_INIT";
export const AJAX_CALL_NOTIFICATION_FINISH = "AJAX_CALL_NOTIFICATION_FINISH";
export const HANDLE_CHECK_NOTIFICATION_SUCCESS =
  "HANDLE_CHECK_NOTIFICATION_SUCCESS";
export const MARK_AS_READ_NOTIFICATIONS_SUCCESS =
  "MARK_AS_READ_NOTIFICATIONS_SUCCESS";
export const MARK_AS_READ_NOTIFICATIONS_FAILURE =
  "MARK_AS_READ_NOTIFICATIONS_FAILURE";
export const HANDLE_FETCH_CAMPAIGN_TYPES_SUCCESS =
  "HANDLE_FETCH_CAMPAIGN_TYPES_SUCCESS";
export const HANDLE_FETCH_CAMPAIGN_TYPES_FAILURE =
  "HANDLE_FETCH_CAMPAIGN_TYPES_FAILURE";
export const HANDLE_FOLLOWER_RANGE_SUCCESS = "HANDLE_FOLLOWER_RANGE_SUCCESS";
export const HANDLE_SELECT_RANGE_PRODUCT_SUCCESS =
  "HANDLE_SELECT_RANGE_PRODUCT_SUCCESS";
export const HANDLE_SELECT_PRODUCT_CHANGE_SUCCESS =
  "HANDLE_SELECT_PRODUCT_CHANGE_SUCCESS";
export const FETCH_ACTIVE_CAMPAIGNS_SUCCESS = "FETCH_ACTIVE_CAMPAIGNS_SUCCESS";
export const FETCH_ACTIVE_NEXT_CAMPAIGNS_SUCCESS =
  "FETCH_ACTIVE_NEXT_CAMPAIGNS_SUCCESS";
export const FETCH_ACTIVE_CAMPAIGNS_FAILURE = "FETCH_ACTIVE_CAMPAIGNS_FAILURE";
export const FETCH_DRAFT_CAMPAIGNS_SUCCESS = "FETCH_DRAFT_CAMPAIGNS_SUCCESS";
export const FETCH_DRAFT_NEXT_CAMPAIGNS_SUCCESS =
  "FETCH_DRAFT_NEXT_CAMPAIGNS_SUCCESS";
export const FETCH_DRAFT_CAMPAIGNS_FAILURE = "FETCH_DRAFT_CAMPAIGNS_FAILURE";
export const FETCH_CLOSED_CAMPAIGNS_SUCCESS = "FETCH_CLOSED_CAMPAIGNS_SUCCESS";
export const FETCH_CLOSED_NEXT_CAMPAIGNS_SUCCESS =
  "FETCH_CLOSED_NEXT_CAMPAIGNS_SUCCESS";
export const FETCH_CLOSED_CAMPAIGNS_FAILURE = "FETCH_CLOSED_CAMPAIGNS_FAILURE";
export const HANDLE_SEARCH_ACTIVE_CAMPAIGNS_SUCCESS =
  "HANDLE_SEARCH_ACTIVE_CAMPAIGNS_SUCCESS";
export const HANDLE_SEARCH_DRAFT_CAMPAIGNS_SUCCESS =
  "HANDLE_SEARCH_DRAFT_CAMPAIGNS_SUCCESS";
export const HANDLE_SEARCH_CLOSED_CAMPAIGNS_SUCCESS =
  "HANDLE_SEARCH_CLOSED_CAMPAIGNS_SUCCESS";
export const HANDLE_SORT_ACTIVE_CAMPAIGNS_SUCCESS =
  "HANDLE_SORT_ACTIVE_CAMPAIGNS_SUCCESS";
export const HANDLE_SORT_DRAFT_CAMPAIGNS_SUCCESS =
  "HANDLE_SORT_DRAFT_CAMPAIGNS_SUCCESS";
export const HANDLE_SORT_CLOSED_CAMPAIGNS_SUCCESS =
  "HANDLE_SORT_CLOSED_CAMPAIGNS_SUCCESS";
export const HANDLE_UPDATE_INFLUENCER_AVATAR_SUCCESS =
  "HANDLE_UPDATE_INFLUENCER_AVATAR_SUCCESS";
export const HANDLE_UPDATE_INFLUENCER_AVATAR_FAILURE =
  "HANDLE_UPDATE_INFLUENCER_AVATAR_FAILURE";
export const HANDLE_ON_DROP_INFLUENCER_FILE = "HANDLE_ON_DROP_INFLUENCER_FILE";
export const HANDLE_FETCH_PALNS_SUCCESS = "HANDLE_FETCH_PALNS_SUCCESS";
export const HANDLE_FETCH_PALNS_FAILURE = "HANDLE_FETCH_PALNS_FAILURE";
export const HANDLE_UPGRADE_PLAN_SUCCESS = "HANDLE_UPGRADE_PLAN_SUCCESS";
export const HANDLE_UPGRADE_PLAN_FAILURE = "HANDLE_UPGRADE_PLAN_FAILURE";
export const BRAND_TRANSACTIONS_SUCCESS = "BRAND_TRANSACTIONS_SUCCESS";
export const BRAND_TRANSACTIONS_FAILURE = "BRAND_TRANSACTIONS_FAILURE";
export const CREDIT_CARD_NOT_ATTACHED = "CREDIT_CARD_NOT_ATTACHED";
export const HANDLE_REGISTER_INIVTED_INFLUENCER_SUCEESS =
  "HANDLE_REGISTER_INIVTED_INFLUENCER_SUCEESS";
export const HANDLE_REGISTER_INIVTED_INFLUENCER_FAILURE =
  "HANDLE_REGISTER_INIVTED_INFLUENCER_FAILURE";
export const HANDLE_RESET_TOKEN_SUCCESS = "HANDLE_RESET_TOKEN_SUCCESS";
export const HANDLE_CATEGORY_SELECT_SUCCESS = "HANDLE_CATEGORY_SELECT_SUCCESS";
export const INFLUENCER_FINANCES_SUCCESS = "INFLUENCER_FINANCES_SUCCESS";
export const INFLUENCER_FINANCES_FAILURE = "INFLUENCER_FINANCES_FAILURE";
export const HANDLE_SELECT_OFFER_CATEGORY_SUCCESS =
  "HANDLE_SELECT_OFFER_CATEGORY_SUCCESS";
export const HANDLE_REMOVE_VOUCHER_PDF_SUCCESS =
  "HANDLE_REMOVE_VOUCHER_PDF_SUCCESS";
export const HANDLE_REMOVE_VOUCHER_PDF_FAILURE =
  "HANDLE_REMOVE_VOUCHER_PDF_FAILURE";
export const HANDLE_CHANGE_SEARCH_TYPE_SUCCESS =
  "HANDLE_CHANGE_SEARCH_TYPE_SUCCESS";
export const HANDLE_TOP_INFLUENCERS_ANALYZED =
  "HANDLE_TOP_INFLUENCERS_ANALYZED";
export const HANDLE_INFLUENCITY_LOCATION_SUCCESS =
  "HANDLE_INFLUENCITY_LOCATION_SUCCESS";
export const HANDLE_INFLUENCITY_CATEGORY_SUCCESS =
  "HANDLE_INFLUENCITY_CATEGORY_SUCCESS";
export const HANDLE_INFLUENCITY_LOCATION_VALUE =
  "HANDLE_INFLUENCITY_LOCATION_VALUE";
export const HANDLE_CHANGE_CREDIBILTY_SUCCESS =
  "HANDLE_CHANGE_CREDIBILTY_SUCCESS";
export const HANDLE_CLEAR_FILTER_SUCCESS = "HANDLE_CLEAR_FILTER_SUCCESS";
export const HANDLE_SELECT_COUNTRY_SUCCESS = "HANDLE_SELECT_COUNTRY_SUCCESS";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_STATES_FAILURE = "FETCH_STATES_FAILURE";
export const HANDLE_SELECT_STATE_SUCCESS = "HANDLE_SELECT_STATE_SUCCESS";
export const HANDLE_SELECT_CITY_SUCCESS = "HANDLE_SELECT_CITY_SUCCESS";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_CITIES_FAILURE = "FETCH_CITIES_FAILURE";
export const FETCH_TIMEZONES_SUCCESS = "FETCH_TIMEZONES_SUCCESS";
export const FETCH_TIMEZONES_FAILURE = "FETCH_TIMEZONES_FAILURE";
export const HANDLE_SELECT_TIMEZONE_SUCCESS = "HANDLE_SELECT_TIMEZONE_SUCCESS";
export const HANDLE_PLATFORM_CATEGORY_SUCCESS =
  "HANDLE_PLATFORM_CATEGORY_SUCCESS";
export const HANDLE_PAYMENT_GATEWAY_ERRORS = "HANDLE_PAYMENT_GATEWAY_ERRORS";
export const HANDLE_BANK_ERRORS = "HANDLE_BANK_ERRORS";
export const HANDLE_SELECT_PRICING_PER_CONTENT_TYPE_SUCCESS =
  "HANDLE_SELECT_PRICING_PER_CONTENT_TYPE_SUCCESS";
export const HANDLE_UPLOAD_VOUCHER_PDF_SUCCESS =
  "HANDLE_UPLOAD_VOUCHER_PDF_SUCCESS";
export const HANDLE_UPLOAD_VOUCHER_PDF_FAILURE =
  "HANDLE_UPLOAD_VOUCHER_PDF_FAILURE";
export const HANDLE_GOAL_SELECT_SUCCESS = "HANDLE_GOAL_SELECT_SUCCESS";
export const DOWNLOAD_FILE_SUCCESS = "DOWNLOAD_FILE_SUCCESS";
export const DOWNLOAD_FILE_FAILURE = "DOWNLOAD_FILE_FAILURE";
export const HANDLE_ACITVE_CAMPAIGN_TAB_SUCCESS =
  "HANDLE_ACITVE_CAMPAIGN_TAB_SUCCESS";
export const CREATE_CAMPAIGN_WITH_SELECTED = "CREATE_CAMPAIGN_WITH_SELECTED";
export const HANDLE_CAMPAIGN_CACHE_CLEAR = "HANDLE_CAMPAIGN_CACHE_CLEAR";
export const HANDLE_CHANGE_FOLLOWRS_SUCCESS = "HANDLE_CHANGE_FOLLOWRS_SUCCESS";
export const CAMPAIGN_CONTENT_UPLOAD_STORY_VIDEO_SUCCESS =
  "CAMPAIGN_CONTENT_UPLOAD_STORY_VIDEO_SUCCESS";
export const CAMPAIGN_CONTENT_UPLOAD_STORY_VIDEO_FAILURE =
  "CAMPAIGN_CONTENT_UPLOAD_STORY_VIDEO_FAILURE";
export const HANDLE_CONNECT_ACCOUNTS_NOT_FOUND =
  "HANDLE_CONNECT_ACCOUNTS_NOT_FOUND";
export const HANDLE_CHANGE_CONTACT_US_SUCCESS =
  "HANDLE_CHANGE_CONTACT_US_SUCCESS";
export const HANDLE_SEND_CONTACT_MESSAGE_SUCCESS =
  "HANDLE_SEND_CONTACT_MESSAGE_SUCCESS";
export const HANDEL_BRAND_SEND_MESSAGE = "HANDEL_BRAND_SEND_MESSAGE";
export const BRAND_CAMPAIGN_SEND_MESSAGE = "BRAND_CAMPAIGN_SEND_MESSAGE";
export const HANDLE_FOLLWERS_LIMIT_ERRORS = "HANDLE_FOLLWERS_LIMIT_ERRORS";
export const CREATE_CAMPAIGN_WITH_INVITE = "CREATE_CAMPAIGN_WITH_INVITE";
export const HANDLE_CHANGE_NON_VERIFIED_SORT_SUCCESS =
  "HANDLE_CHANGE_NON_VERIFIED_SORT_SUCCESS";
export const HANDLE_PAYMENT_PRICE_ERRORS = "HANDLE_PAYMENT_PRICE_ERRORS";
export const HANDLE_NOT_PUBLISHED_YET_ERRORS =
  "HANDLE_NOT_PUBLISHED_YET_ERRORS";
export const HANDLE_CHANGE_PUSH_NOTIFY_SUCCESS =
  "HANDLE_CHANGE_PUSH_NOTIFY_SUCCESS";
export const HANDLE_CHANGE_EMAIL_NOTIFY_SUCCESS =
  "HANDLE_CHANGE_EMAIL_NOTIFY_SUCCESS";
export const HANDLE_DOWNLOAD_INVOICE_SUCCESS =
  "HANDLE_DOWNLOAD_INVOICE_SUCCESS";
export const HANDLE_DOWNLOAD_INVOICE_FAILURE =
  "HANDLE_DOWNLOAD_INVOICE_FAILURE";
export const HANDLE_INFLUENCER_WITHDRAWAL_REQUEST_SUCCESS =
  "HANDLE_INFLUENCER_WITHDRAWAL_REQUEST_SUCCESS";
export const HANDLE_INFLUENCER_WITHDRAWAL_REQUEST_FAILURE =
  "HANDLE_INFLUENCER_WITHDRAWAL_REQUEST_FAILURE";
export const HANDLE_BRAND_CONNECT_INSTAGRAM_SUBMIT_SUCCESS =
  "HANDLE_BRAND_CONNECT_INSTAGRAM_SUBMIT_SUCCESS";
export const HANDLE_BRAND_CONNECT_INSTAGRAM_SUBMIT_FAILURE =
  "HANDLE_BRAND_CONNECT_INSTAGRAM_SUBMIT_FAILURE";
export const FETCH_BRAND_NOTABLE_USERS_SUCCESS =
  "FETCH_BRAND_NOTABLE_USERS_SUCCESS";
export const FETCH_BRAND_NOTABLE_USERS_FAILURE =
  "FETCH_BRAND_NOTABLE_USERS_FAILURE";
export const CREATE_CAMPAIGN_WITH_NOTABLE_USERS =
  "CREATE_CAMPAIGN_WITH_NOTABLE_USERS";
export const NOT_EAMIL_EXIST = "NOT_EAMIL_EXIST";
export const HANDLE_SORT_NOTABLE_USERS = "HANDLE_SORT_NOTABLE_USERS";
export const ERROR_MESSAGE_DATA = "ERROR_MESSAGE_DATA";
export const HANDLE_CLEAR_ERROR_SUCCESS = "HANDLE_CLEAR_ERROR_SUCCESS";
export const HANDLE_NOTABLE_USER_REPORT_SUCCESS =
  "HANDLE_NOTABLE_USER_REPORT_SUCCESS";
export const HANDLE_NOTABLE_USER_REPORT_FAILURE =
  "HANDLE_NOTABLE_USER_REPORT_FAILURE";
export const HANDLE_CLOSE_PROCEED_MODAL = "HANDLE_CLOSE_PROCEED_MODAL";
export const ADD_NOTABEL_INFLUENCER = "ADD_NOTABEL_INFLUENCER";
export const REMOVE_NOTABLE_INFLUENCER = "REMOVE_NOTABLE_INFLUENCER";
export const REMOVE_ALL_NOTABLE_INFLUENCER = "REMOVE_ALL_NOTABLE_INFLUENCER";
export const ADD_INFLUENCERS = "ADD_INFLUENCERS";
export const REMOVE_INFLUENCERS = "REMOVE_INFLUENCERS";
export const REMOVE_ALL_ADDED_INFLUENCERS = "REMOVE_ALL_ADDED_INFLUENCERS";
export const BRADN_REFRESH_PROFILE_INIT = "BRADN_REFRESH_PROFILE_INIT";
export const BRADN_REFRESH_PROFILE_FINISH = "BRADN_REFRESH_PROFILE_FINISH";
export const HANDLE_USER_CLOSE_ACCOUNT_SUCCESS =
  "HANDLE_USER_CLOSE_ACCOUNT_SUCCESS";
export const HANDLE_USER_CLOSE_ACCOUNT_FAILURE =
  "HANDLE_USER_CLOSE_ACCOUNT_FAILURE";
export const CLOSE_ACCOUNT_INIT = "CLOSE_ACCOUNT_INIT";
export const CLOSE_ACCOUNT_FINSH = "CLOSE_ACCOUNT_FINSH";
export const BRAND_REPORTS_SUCCESS = "BRAND_REPORTS_SUCCESS";
export const BRAND_REPORTS_FAILURE = "BRAND_REPORTS_FAILURE";
export const FECTH_BRAND_ANALYZED_INFLUENCERS_SUCCESS =
  "FECTH_BRAND_ANALYZED_INFLUENCERS_SUCCESS";
export const FECTH_BRAND_ANALYZED_INFLUENCERS_FAILURE =
  "FECTH_BRAND_ANALYZED_INFLUENCERS_FAILURE";
export const ADD_ANALYZED_INFLUENCER = "ADD_ANALYZED_INFLUENCER";
export const REMOVE_ANALYZED_INFLUENCER = "REMOVE_ANALYZED_INFLUENCER";
export const REMOVE_ALL_ANALYZED_INFLUENCERS =
  "REMOVE_ALL_ANALYZED_INFLUENCERS";
export const CREATE_CAMPAIGN_WITH_ANALYZED_USERS =
  "CREATE_CAMPAIGN_WITH_ANALYZED_USERS";
export const HANDLE_CHANGE_SEARCH_ANALYZER_SUCCESS =
  "HANDLE_CHANGE_SEARCH_ANALYZER_SUCCESS";
export const SEARCH_ANALYZER_SUCCESS = "SEARCH_ANALYZER_SUCCESS";
export const ANALYZER_SEARCH_INIT = "ANALYZER_SEARCH_INIT";
export const ANALYZER_SEARCH_FINSH = "ANALYZER_SEARCH_FINSH";
export const HANDLE_CLOSE_DROPDOWN_SUCCESS = "HANDLE_CLOSE_DROPDOWN_SUCCESS";
export const HANDLE_OPEN_DROPDOWN_SUCCESS = "HANDLE_OPEN_DROPDOWN_SUCCESS";
export const HANDLE_BUDGET_ERRORS = "HANDLE_BUDGET_ERRORS";
export const BRAND_CHANGE_CAMPAING_DROPDOWN_SUCCESS =
  "BRAND_CHANGE_CAMPAING_DROPDOWN_SUCCESS";
export const FETCH_ACTIVE_DROPDOWN_CAMPAIGNS_SUCCESS =
  "FETCH_ACTIVE_DROPDOWN_CAMPAIGNS_SUCCESS";
export const FETCH_ACTIVE_DROPDOWN_CAMPAIGNS_FAILURE =
  "FETCH_ACTIVE_DROPDOWN_CAMPAIGNS_FAILURE";
export const FETCH_DRAFT_DROPDOWN_CAMPAIGNS_SUCCESS =
  "FETCH_DRAFT_DROPDOWN_CAMPAIGNS_SUCCESS";
export const FETCH_DRAFT_DROPDOWN_CAMPAIGNS_FAILURE =
  "FETCH_DRAFT_DROPDOWN_CAMPAIGNS_FAILURE";
export const FETCH_CLOSED_DROPDOWN_CAMPAIGNS_SUCCESS =
  "FETCH_CLOSED_DROPDOWN_CAMPAIGNS_SUCCESS";
export const FETCH_CLOSED_DROPDOWN_CAMPAIGNS_FAILURE =
  "FETCH_CLOSED_DROPDOWN_CAMPAIGNS_FAILURE";
export const HANDLE_CANCEL_CONNECT_INSTAGRAM =
  "HANDLE_CANCEL_CONNECT_INSTAGRAM";
export const HANDLE_SORT_NOTABLE_LIKERS = "HANDLE_SORT_NOTABLE_LIKERS";
export const HANDLE_SEARCH_TYPE = "HANDLE_SEARCH_TYPE";
export const HANDLE_DOWNLOAD_FILE_SUCCESS = "HANDLE_DOWNLOAD_FILE_SUCCESS";
export const HANDLE_DOWNLOAD_FILE_FAILURE = "HANDLE_DOWNLOAD_FILE_FAILURE";
export const FETCH_MESSAGE_INIT = "FETCH_MESSAGE_INIT";
export const FETCH_MESSAGE_FINSH = "FETCH_MESSAGE_FINSH";
export const HANDLE_NOTIFY_TYPE = "HANDLE_NOTIFY_TYPE";
export const HANDLE_CHANGE_CAMPAIGN_ID = "HANDLE_CHANGE_CAMPAIGN_ID";
export const DUPLICATE_CAMPAIGN_INIT = "DUPLICATE_CAMPAIGN_INIT";
export const DUPLICATE_CAMPAIGN_FINSH = "DUPLICATE_CAMPAIGN_FINSH";
export const BRAND_DUPLICATE_CAMPAIGN_SUCCESS =
  "BRAND_DUPLICATE_CAMPAIGN_SUCCESS";
export const BRAND_DUPLICATE_CAMPAIGN_FAILURE =
  "BRAND_DUPLICATE_CAMPAIGN_FAILURE";
export const HANDLE_FILL_SUGGESTED_FEE = "HANDLE_FILL_SUGGESTED_FEE ";
export const HANDLE_SUGGESTED_FEE_FLAG = "HANDLE_SUGGESTED_FEE_FLAG ";
export const HANDLE_INSTRUCTION_CHANGE = "HANDLE_INSTRUCTION_CHANGE ";
export const HANDLE_WORDING_CHANGE = "HANDLE_WORDING_CHANGE ";
export const HANDLE_SUBSCRIPTION_PALNS_STATUS_SUCCESS =
  "HANDLE_SUBSCRIPTION_PALNS_STATUS_SUCCESS";
export const HANDLE_SUBSCRIPTION_PALNS_STATUS_FAILURE =
  "HANDLE_SUBSCRIPTION_PALNS_STATUS_FAILURE";
export const BRAND_ANALYZED_INFLUENCERS_INIT =
  "BRAND_ANALYZED_INFLUENCERS_INIT";
export const BRAND_ANALYZED_INFLUENCERS_FINSH =
  "BRAND_ANALYZED_INFLUENCERS_FINSH";
export const HANDLE_FETCH_BRAND_PALNS_SUCCESS =
  "HANDLE_FETCH_BRAND_PALNS_SUCCESS";
export const HANDLE_FETCH_BRAND_PALNS_FAILURE =
  "HANDLE_FETCH_BRAND_PALNS_FAILURE";
export const HANDLE_BILLING_TYPE = "HANDLE_BILLING_TYPE";
export const HANDLE_RESET_CANCEL_SUBSCRIPTION_FORM =
  "HANDLE_RESET_CANCEL_SUBSCRIPTION_FORM";
export const HANDLE_ADD_CANCEL_SUBSCRIPTION_FORM =
  "HANDLE_ADD_CANCEL_SUBSCRIPTION_FORM";
export const HANDLE_FETCH_PROMOTIONAL_PALNS_SUCCESS =
  "HANDLE_FETCH_PROMOTIONAL_PALNS_SUCCESS";
export const HANDLE_FETCH_PROMOATIONAL_PALNS_FAILURE =
  "HANDLE_FETCH_PROMOATIONAL_PALNS_FAILURE";
export const HANDLE_BRAND_CANCEL_SUBSCRIPTION_SUCCESS =
  "HANDLE_BRAND_CANCEL_SUBSCRIPTION_SUCCESS";
export const HANDLE_BRAND_CANCEL_SUBSCRIPTION_FAILURE =
  "HANDLE_BRAND_CANCEL_SUBSCRIPTION_FAILURE";
export const REFRESH_REPORTS_SUCCESS = "REFRESH_REPORTS_SUCCESS";
export const REFRESH_REPORTS_FAILURE = "REFRESH_REPORTS_FAILURE";
export const HANDLE_CREDITS_ERRORS = "HANDLE_CREDITS_ERRORS";
export const HANDLE_BRAND_CAMPAIGN_ACTION = "HANDLE_BRAND_CAMPAIGN_ACTION";
export const FETCH_PLAN_SUMMARY_SUCCESS = "FETCH_PLAN_SUMMARY_SUCCESS";
export const FETCH_PLAN_SUMMARY_FAILURE = "FETCH_PLAN_SUMMARY_FAILURE";
export const HANDLE_CHANGE_COUPON_SUCCESS = "HANDLE_CHANGE_COUPON_SUCCESS";
export const HANDLE_APPLY_COUPON_SUCEESS = "HANDLE_APPLY_COUPON_SUCEESS";
export const HANDLE_APPLY_COUPON_FAILURE = "HANDLE_APPLY_COUPON_FAILURE";
export const HANDLE_REGISTER_BRAND_SUBMIT_SUCEESS =
  "HANDLE_REGISTER_BRAND_SUBMIT_SUCEESS";
export const HANDLE_REGISTER_BRAND_SUBMIT_FAILURE =
  "HANDLE_REGISTER_BRAND_SUBMIT_FAILURE";
export const HANDLE_BRAND_LOGIN_SUCCESS = "HANDLE_BRAND_LOGIN_SUCCESS";
export const BRAND_LOGIN_INIT = "BRAND_LOGIN_INIT";
export const BRAND_LOGIN_FINISH = "BRAND_LOGIN_FINISH";
export const HANDLE_GET_STRATED_PAID = "HANDLE_GET_STRATED_PAID";
export const HANDLE_GET_STRATED_FREE = "HANDLE_GET_STRATED_FREE";
export const NO_CREDITS_EXIST = "NO_CREDITS_EXIST";
export const HANDLE_HIDE_MODAL_SUCCESS = "HANDLE_HIDE_MODAL_SUCCESS";
export const HANDLE_DOWN_GRADE_MODAL_HIDE = "HANDLE_DOWN_GRADE_MODAL_HIDE";
export const HANDLE_DOWNGRADE_PLAN_SUCCESS = "HANDLE_DOWNGRADE_PLAN_SUCCESS";
export const HANDLE_DOWNGRADE_PLAN_FAILURE = "HANDLE_DOWNGRADE_PLAN_FAILURE";
export const HTTP_404_ERROR = "HTTP_404_ERROR";
export const HTTP_500_ERROR = "HTTP_500_ERROR";
export const HTTP_OTHER_ERROR = "HTTP_OTHER_ERROR";
export const FETCH_SUBSCRIPTION_REPORTS_SUCCESS =
  "FETCH_SUBSCRIPTION_REPORTS_SUCCESS";
export const HANDLE_BRAND_GMAIL_VERIFY_TOKEN_SUCCESS =
  "HANDLE_BRAND_GMAIL_VERIFY_TOKEN_SUCCESS";
export const FETCH_GMAIL_SETTINGS_SUCCESS = "FETCH_GMAIL_SETTINGS_SUCCESS";
export const HANDLE_GMAIL_SETTING_CHANGE = "HANDLE_GMAIL_SETTING_CHANGE";
export const HANDLE_GMAIL_UPDATE_SUCCESS = "HANDLE_GMAIL_UPDATE_SUCCESS";
export const HANDLE_GMAIL_UPDATE_FAILED = "HANDLE_GMAIL_UPDATE_FAILED";
export const HANDLE_SHOPIFY_STORE_URL_CHANGE =
  "HANDLE_SHOPIFY_STORE_URL_CHANGE";
export const HANDLE_SHOPIFY_SYNC_SUCCESS = "HANDLE_SHOPIFY_SYNC_SUCCESS";
export const HANDLE_BRAND_SHOPIFY_VERIFY_TOKEN_SUCCESS =
  "HANDLE_BRAND_SHOPIFY_VERIFY_TOKEN_SUCCESS";
export const HANDLE_GMAIL_DISCONNECT_SUCCESS =
  "HANDLE_GMAIL_DISCONNECT_SUCCESS";
export const BRAND_LISTS_SUCCESS = "BRAND_LISTS_SUCCESS";
export const BRAND_LISTS_FAILURE = "BRAND_LISTS_FAILURE";
export const HANDLE_SEARCH_LISTS_SUCCESS = "HANDLE_SEARCH_LISTS_SUCCESS";
export const HANDLE_SORT_LIST_SUCCESS = "HANDLE_SORT_LIST_SUCCESS";
export const ADD_NEW_LIST_SUCCESS = "ADD_NEW_LIST_SUCCESS";
export const ADD_NEW_LIST_FAILURE = "ADD_NEW_LIST_FAILURE";
export const HANDLE_LIST_VALIDATION_ERRORS = "HANDLE_LIST_VALIDATION_ERRORS";
export const HANDLE_SHOW_CREATE_NEW_LIST = "HANDLE_SHOW_CREATE_NEW_LIST";
export const HANDLE_HIDE_CREATE_NEW_LIST = "HANDLE_HIDE_CREATE_NEW_LIST";
export const ADD_INFLUENCER_TO_LIST_SUCCESS = "ADD_INFLUENCER_TO_LIST_SUCCESS";
export const ADD_INFLUENCER_TO_LIST_FAILURE = "ADD_INFLUENCER_TO_LIST_FAILURE";
export const HANDLE_SHOW_LIST_MODAL = "HANDLE_SHOW_LIST_MODAL";
export const HANDLE_HIDE_LIST_MODAL = "HANDLE_HIDE_LIST_MODAL";
export const BRAND_INSTAGRAM_LIST_SUCCESS = "BRAND_INSTAGRAM_LIST_SUCCESS";
export const BRAND_INSTAGRAM_LIST_FAILURE = "BRAND_INSTAGRAM_LIST_FAILURE";
export const HANDLE_SORT_LIST_INFLUENCER_SUCCESS =
  "HANDLE_SORT_LIST_INFLUENCER_SUCCESS";
export const INVALID_BODY_PARAMS = "INVALID_BODY_PARAMS";
export const BRAND_INSTAGRAM_LIST_VIEW_SUCCESS =
  "BRAND_INSTAGRAM_LIST_VIEW_SUCCESS";
export const BRAND_INSTAGRAM_LIST_VIEW_FAILURE =
  "BRAND_INSTAGRAM_LIST_VIEW_FAILURE";
export const HANDLE_LIST_NAME_EDIT_SUCCESS = "HANDLE_LIST_NAME_EDIT_SUCCESS";
export const HANLDE_UPDATE_LIST_SUCCESS = "HANLDE_UPDATE_LIST_SUCCESS";
export const HANDLE_SUBJECT_CHANGE_SUCCESS = "HANDLE_SUBJECT_CHANGE_SUCCESS";
export const HANDLE_VERIFIED_MESSAGE_CHANGE_SUCCESS =
  "HANDLE_VERIFIED_MESSAGE_CHANGE_SUCCESS";
export const HANDLE_NON_VERIFIED_MESSAGE_CHANGE_SUCCESS =
  "HANDLE_NON_VERIFIED_MESSAGE_CHANGE_SUCCESS";
export const HANDLE_GMAIL_CONNECTED_MESSAGE_CHANGE_SUCCESS =
  "HANDLE_GMAIL_CONNECTED_MESSAGE_CHANGE_SUCCESS";
export const BRAND_SEARCH_LISTS_SUCCESS = "BRAND_SEARCH_LISTS_SUCCESS";
export const AJAX_CALL_LIST_INIT = "AJAX_CALL_LIST_INIT";
export const AJAX_CALL_LIST_FINISH = "AJAX_CALL_LIST_FINISH";
export const HANDLE_SELECT_PLATFORM = "HANDLE_SELECT_PLATFORM";
export const HANDLE_CANCEL_INSTAGRAM_CONNECT =
  "HANDLE_CANCEL_INSTAGRAM_CONNECT";
export const HANDLE_SELECT_SEARCH_BY_SUCCESS =
  "HANDLE_SELECT_SEARCH_BY_SUCCESS";
export const INFLUENCER_CAMPAIGN_DETAILS_INIT =
  "INFLUENCER_CAMPAIGN_DETAILS_INIT";
export const INFLUENCER_CAMPAIGN_DETAILS_FINISH =
  "INFLUENCER_CAMPAIGN_DETAILS_FINISH";
export const HANDLE_INFLUENCER_TAB_VALUE = "HANDLE_INFLUENCER_TAB_VALUE";
export const HANDLE_INFLUENCER_DISCOUNT_VALUE =
  "HANDLE_INFLUENCER_DISCOUNT_VALUE";
export const HANDLE_FOLLOWER_DISCOUNT_VALUE = "HANDLE_FOLLOWER_DISCOUNT_VALUE";
export const HANDLE_SAVE_NAME_VALUE = "HANDLE_SAVE_NAME_VALUE";
export const HANDLE_AFFILIATE_COMMISSION_VALUE =
  "HANDLE_AFFILIATE_COMMISSION_VALUE";
export const HANDLE_CLEAR_SELECTED_INFLUENCERS =
  "HANDLE_CLEAR_SELECTED_INFLUENCERS";
export const BRAND_INFLUENCIAL_FOLLOWERS_LOADING =
  "BRAND_INFLUENCIAL_FOLLOWERS_LOADING";
export const HANDLE_CLOSE_ERROR_MODAL = "HANDLE_CLOSE_ERROR_MODAL";
export const HANDLE_RESPONSE_SUCCESS_FALSE = "HANDLE_RESPONSE_SUCCESS_FALSE";
export const ADD_SELECTED_ANALYZER_INFLUENCER =
  "ADD_SELECTED_ANALYZER_INFLUENCER ";
export const REMOVE_SELECTED_ANALYZER_INFLUENCER =
  "REMOVE_SELECTED_ANALYZER_INFLUENCER";
export const EMPTY_SELECTED_ANALYZED_INFLUENCERS =
  "EMPTY_SELECTED_ANALYZED_INFLUENCERS";
export const HANDLE_REFRESH_PROFILE_LOADING = "HANDLE_REFRESH_PROFILE_LOADING";
export const HANDLE_YOUTUBE_CONNECT_RES = "HANDLE_YOUTUBE_CONNECT_RES";
export const SETTING_PLATFORM_SELECT_TIKTOK = "SETTING_PLATFORM_SELECT_TIKTOK";
export const FETCH_DOMAIN_INFO_REQUEST = 'FETCH_DOMAIN_INFO_REQUEST';
export const FETCH_DOMAIN_INFO_SUCCESS = 'FETCH_DOMAIN_INFO_SUCCESS';
export const FETCH_DOMAIN_INFO_FAILURE = 'FETCH_DOMAIN_INFO_FAILURE';
export const SET_DOMAIN_INFO_REQUEST = 'SET_DOMAIN_INFO_REQUEST';
export const SET_DOMAIN_INFO_FAILURE = 'SET_DOMAIN_INFO_FAILURE';
export const SET_DOMAIN_INFO_SUCCESS = 'SET_DOMAIN_INFO_SUCCESS';
export const SET_RESELLER_INFO = 'SET_RESELLER_INFO';
export const SET_SHOW_ERROR = 'SET_SHOW_ERROR';

