import React from "react";
import ReportDetailHeader from "./ReportDetailHeader";
import { useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import ReportDetailTabs from "./ReportDetailTabs";
import { Helmet } from "react-helmet";
import moment from "moment";
import CustomLoader from "@components/global/CustomLoader";

const BrandReportDetail = () => {
  const isLoading = useSelector((state) => state.BrandReports.isReportFetching);
  const domain = useSelector((state) => state?.domain?.data?.company_name);
  const report = useSelector((state) => state.BrandReports.currentReportDetail);
  const url = window.location.href;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reporting | {domain ? domain : "Btrendy"}</title>
        <link rel="canonical" href={url} />
      </Helmet>
      <div className="">
        {(!report.campaign_end_date ||
          moment(report.campaign_end_date).isAfter(moment())) &&
          !report.isFetched &&
          !isLoading && (
            <div
              class="flex items-center bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-5"
              role="alert"
            >
              <svg
                class="fill-current w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
              </svg>
              <p>
                Your report is under queue. Once fetched you will be notified.
              </p>
            </div>
          )}
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640]">
          {isLoading ? (
            <div className="flex relative items-center justify-center h-[78vh]">
              <CustomLoader />
            </div>
          ) : (
            <>
              <ReportDetailHeader />
              <ReportDetailTabs />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BrandReportDetail;
