import { Component } from "react";
import { connect } from "react-redux";
import IqInfluencerDiscoveryTab from "@components/BrandInfluencerDiscover/NavTabs";
import InstagramFilters from "./InstagramFilters";
import InstagramItems from "./InstagramItems";
import { Helmet } from "react-helmet";
import SubHeader from "@components/Header/SubHeader";

class BrandInfluencersDiscoverInstagram extends Component {
    constructor(props) {
        super(props);
        this.timeout = 0;
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        if (this.timeout) clearTimeout(this.timeout);
        let payload = Object.assign({}, this.props.payload);
        const actions = Object.assign([], this.props.actions);
        const form = Object.assign({}, this.props.form);
        if (actions.length > 0) {
            payload = {
                ...payload,
                filter: {
                    ...payload.filter,
                    actions: actions,
                },
            };
        }
        let query = {
            platform: "instagram",
            payload: payload,
            override_filter: form.override_filter,
        };

        this.timeout = setTimeout(() => {
            this.props.searchInfluencers(query);
            this.props.searchInfluencersCount(query);
        }, 500);
    };

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>
                        Discover | Instagram | {this.props?.domain ? this.props?.domain : "Btrendy"}
                    </title>
                </Helmet>
                <SubHeader title={'Find Influencers'} />
                <div className="mb-12">
                    <div className="pt-6">
                        <IqInfluencerDiscoveryTab />
                        <InstagramFilters customContainer="px-0" />
                    </div>
                    <div className="mt-12">
                        <InstagramItems newCampaignWithSelected={true} />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ influencerSearch,domain }) => {
    return {
        platform: influencerSearch.platform,
        form: influencerSearch.form,
        payload: influencerSearch.payload,
        actions: influencerSearch.actions,
        domain: domain?.data?.company_name,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("@store/redux/InfluencerSearchRedux");
    return {
        ...ownProps,
        ...stateProps,
        searchInfluencers: (data) => {
            actions.searchInfluencers(dispatch, data);
        },
        searchInfluencersCount: (data) => {
            actions.searchInfluencersCount(dispatch, data);
        },
    };
};

export default connect(
    mapStateToProps,
    undefined,
    mergeProps
)(BrandInfluencersDiscoverInstagram);
