// actions/domainActions.js
import axios from 'axios';

import Api from '@services/axios';
import { FETCH_DOMAIN_INFO_FAILURE, FETCH_DOMAIN_INFO_REQUEST, FETCH_DOMAIN_INFO_SUCCESS, SET_DOMAIN_INFO_FAILURE, SET_DOMAIN_INFO_REQUEST, SET_DOMAIN_INFO_SUCCESS } from '@store/constants/action-types';
import { toast } from 'react-toastify';
import Emitter from '@constants/Emitter';

export const fetchDomainInfo = (query, ownProps) => async (dispatch) => {
  dispatch({ type: FETCH_DOMAIN_INFO_REQUEST });
  Api.fetchDomainInfo(query)
    .then((res) => {
      if (res.data.error) {
        dispatch({
          type: FETCH_DOMAIN_INFO_REQUEST,
        });
      } else if (res.data.errors) {
        dispatch({
          type: FETCH_DOMAIN_INFO_FAILURE,
          payload: res.data.errors,
        });
      } else {
        dispatch({
          type: FETCH_DOMAIN_INFO_SUCCESS,
          payload: res?.data,
        });
        // dispatch(refreshReports());
        // ownProps.Navigate("/products");
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCH_DOMAIN_INFO_FAILURE,
        payload: error,
      });
    });
};

export const setDomainInfo = (query, ownProps) => async (dispatch) => {
  dispatch({ type: SET_DOMAIN_INFO_REQUEST });

  try {
    const res = await Api.setDomainInfo(query);

    if (res.data.error) {
      dispatch({ type: SET_DOMAIN_INFO_FAILURE, payload: res.data.error });
    } else if (res.data.errors) {
      toast.error(res?.data?.errors?.[Object.keys(res.data.errors)?.[0]]?.[0])
      dispatch({ type: SET_DOMAIN_INFO_FAILURE, payload: res.data.errors });
    } else if (res?.status === 200 || res?.status === 201) {
      dispatch({ type: SET_DOMAIN_INFO_SUCCESS, payload: res.data });
      Emitter.emit("MESSAGE_POPUP", {
        title: "Request Received Successfully",
        description: "We have successfully recieved your request, our administrator will contact you shortly.",
        button: "/",
        button_text: "Go Home",
      });
    }
  } catch (error) {
    dispatch({ type: SET_DOMAIN_INFO_FAILURE, payload: error });
  }
};
