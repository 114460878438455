// reducers/domainReducer.js

import { FETCH_DOMAIN_INFO_FAILURE, FETCH_DOMAIN_INFO_REQUEST, FETCH_DOMAIN_INFO_SUCCESS, SET_DOMAIN_INFO_FAILURE, SET_DOMAIN_INFO_REQUEST, SET_DOMAIN_INFO_SUCCESS, SET_RESELLER_INFO, SET_SHOW_ERROR } from "@store/constants/action-types";


const initialState = {
    loading: false,
    data: null,
    error: null,
    resellerData: {},
    requestLoading: false,
    appUrl: "",
    requestData: null,
    requestError: null,
    showError: false,
};

export const domainReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DOMAIN_INFO_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_DOMAIN_INFO_SUCCESS:
            return {
                ...state, loading: false, data: action.payload, appUrl: action.payload?.subdomain === "localhost" ? `${action.payload?.subdomain}:3000` : `https://${action.payload?.subdomain}`};
        case FETCH_DOMAIN_INFO_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case SET_RESELLER_INFO:
            return { ...state, resellerData: { ...state.resellerData , ...action.payload} };
        case SET_DOMAIN_INFO_REQUEST:
            return { ...state, requestLoading: true, requestError: null };
        case SET_DOMAIN_INFO_SUCCESS:
            return { ...state, requestLoading: false, requestData: action.payload, resellerData: {} };
        case SET_DOMAIN_INFO_FAILURE:
            return { ...state, requestLoading: false, requestError: action.payload };
        case SET_SHOW_ERROR:
            return { ...state, showError: action.payload };
        default:
            return state;
    }
};