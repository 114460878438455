// import { combineReducers } from "redux";
import { persistCombineReducers } from "redux-persist";
import ProductsReducer from "../reducers/ProductsReducer";
import AddProductReducer from "../reducers/AddProductReducer";
import AddVoucherReducer from "../reducers/AddVoucherReducer";
import EditProductReducer from "../reducers/EditProductReducer";
import BasicInfoReducer from "../reducers/BasicInfoReducer";
import SettingAccountReducer from "../reducers/SettingAccountReducer";
import LoginReducer from "../reducers/LoginReducer";
import SettingPlatformReducer from "../reducers/SettingPlatformReducer";
import RegisterReducer from "../reducers/RegisterReducer";
import ForgetPasswordReducer from "../reducers/ForgetPasswordReducer";
import SettingPaymentReducer from "../reducers/SettingPaymentReducer";
import HeaderReducer from "../reducers/HeaderReducer";
import SettingSubscriptionReducer from "../reducers/SettingSubscriptionReducer";
import BrandDashboardReducer from "../reducers/BrandDashboardReducer";
import ContactReducer from "../reducers/ContactReducer";
import BrandAmbassadorsReducer from "../reducers/BrandAmbassadorsReducer";
import SettingGmailReducer from "../reducers/SettingGmailReducer";
import SettingShopifyReducer from "../reducers/SettingShopifyReducer";
import BrandListsReducer from "../reducers/BrandListsReducer";

import { reducer as InfluencerSearchRedux } from "../redux/InfluencerSearchRedux";
import { reducer as BrandReportsRedux } from "../redux/BrandReportsRedux";
import { reducer as InfluencialFollowersRedux } from "../redux/InfluencialFollowersRedux";
import { reducer as ErrorHandlerRedux } from "../redux/ErrorHandlerRedux";
import { reducer as InfluencerAnalyzerRedux } from "../redux/InfluencerAnalyzerRedux";
import { reducer as AppsumoRedux } from "../redux/AppsumoRedux";
import { reducer as BrandListRedux } from "../redux/BrandListRedux";
import { reducer as SmtpRedux } from "../redux/SmtpRedux";
import { reducer as SavedSearchRedux } from "../redux/SavedSearch";
import { reducer as EmailMatchRedux } from "../redux/EmailMatchRedux";
import { reducer as SettingRedux } from "../redux/SettingRedux";
import { reducer as SubAccountRedux } from "../redux/SubAccountRedux";
import { reducer as CampaignRedux } from "../redux/CampaignRedux";
import { reducer as MarketingToolRedux } from "../redux/MarketingToolRedux";
import { reducer as GoogleAnalyticsRedux } from "../redux/GoogleAnalyticsRedux";
import { reducer as BillingRedux } from "../redux/BillingRedux";

import storage from "redux-persist/lib/storage";
import { domainReducer } from "./SetDomainInfoReducer";
const config = {
  key: "root",
  storage,
  whitelist: ["marketingTool", "RegisterReducer"],
};
export default persistCombineReducers(config, {
  ProductsReducer: ProductsReducer,
  AddProductReducer: AddProductReducer,
  AddVoucherReducer: AddVoucherReducer,
  EditProductReducer: EditProductReducer,
  BasicInfoReducer: BasicInfoReducer,
  SettingAccountReducer: SettingAccountReducer,
  LoginReducer: LoginReducer,
  SettingPlatformReducer: SettingPlatformReducer,
  RegisterReducer: RegisterReducer,
  ForgetPasswordReducer: ForgetPasswordReducer,
  HeaderReducer: HeaderReducer,
  SettingSubscriptionReducer: SettingSubscriptionReducer,
  BrandDashboardReducer: BrandDashboardReducer,
  ContactReducer: ContactReducer,
  BrandAmbassadorsReducer: BrandAmbassadorsReducer,
  SettingGmailReducer: SettingGmailReducer,
  SettingShopifyReducer: SettingShopifyReducer,
  BrandListsReducer: BrandListsReducer,
  influencerSearch: InfluencerSearchRedux,
  BrandReports: BrandReportsRedux,
  influencialFollowers: InfluencialFollowersRedux,
  errorHandler: ErrorHandlerRedux,
  influencerAnalyzer: InfluencerAnalyzerRedux,
  Appsumo: AppsumoRedux,
  brandList: BrandListRedux,
  campaign: CampaignRedux,
  smtp: SmtpRedux,
  savedSearch: SavedSearchRedux,
  SettingPaymentReducer: SettingPaymentReducer,
  listEmailMatch: EmailMatchRedux,
  settings: SettingRedux,
  subAccount: SubAccountRedux,
  marketingTool: MarketingToolRedux,
  googleAnalytics: GoogleAnalyticsRedux,
  billing: BillingRedux,
  domain: domainReducer,
});
