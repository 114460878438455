import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, forwardRef, useImperativeHandle } from "react";
import { RxCross2 } from "react-icons/rx";

const AddPricingPlanPopup = forwardRef(({ onClose }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});

    useImperativeHandle(ref, () => ({
        open() {
            setIsOpen(true);
        },
        close() {
            setIsOpen(false);
        },
    }));

    const close = () => {
        setIsOpen(false);
    };
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={close}>
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-bgBodyLight p-6 text-left align-middle shadow-xl transition-all">
                                <div className="flex justify-between items-center">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-2xl font-bold text-center leading-6 text-white"
                                    >
                                        Add a pricing plan
                                    </Dialog.Title>
                                    <div
                                        className="cursor-pointer text-white"
                                        onClick={close}
                                    >
                                        <RxCross2 size={20} />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-y-5 mt-5">
                                    <div className="space-y-2">
                                        <label
                                            htmlFor="plan_name"
                                            className="text-white"
                                        >
                                            Plan name
                                        </label>
                                        <input
                                            type="text"
                                            id="plan_name"
                                            placeholder="Plan name"
                                            className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                        />
                                    </div>
                                    <div className="flex items-center gap-3">
                                        <div className="space-y-2 w-full">
                                            <label
                                                htmlFor=""
                                                className="text-white"
                                            >
                                                Interval of plan
                                            </label>
                                            <select className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                                                <option
                                                    value="monthly"
                                                    className="bg-bgBodyDark"
                                                >
                                                    Monthly
                                                </option>
                                                <option
                                                    value="yearly"
                                                    className="bg-bgBodyDark"
                                                >
                                                    Yearly
                                                </option>
                                            </select>
                                        </div>
                                        <div className="space-y-2 w-full">
                                            <label
                                                htmlFor="no_users"
                                                className="text-white"
                                            >
                                                Number of users
                                            </label>
                                            <input
                                                type="text"
                                                id="no_users"
                                                placeholder="Number of users"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-2">
                                        <label
                                            htmlFor="charge_bank"
                                            className="text-white"
                                        >
                                            Name of the charge on the bank
                                            account of your customer
                                        </label>
                                        <input
                                            type="text"
                                            id="charge_bank"
                                            placeholder="My company name"
                                            className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                        />
                                    </div>
                                    <div className="space-y-3">
                                        <p className="text-white">
                                            Is Active ?
                                        </p>
                                        <label className="cursor-pointer flex items-start text-sm text-white font-normal mr-2">
                                            <input
                                                type="checkbox"
                                                className="hidden peer"
                                            />
                                            <span className="mr-3 peer-checked:bg-primary shrink-0 bg-white h-[18px] w-[18px] before:content-[''] relative before:absolute before:bottom-[4.2px] before:left-[2.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-primary rounded-sm"></span>
                                            If you check it to make it active,
                                            customers will be able to upgrade on
                                            this plan and use it.
                                        </label>
                                    </div>
                                    <div
                                        class="flex items-start gap-y-2 flex-col p-2 justify-center shrink-0 text-sm text-blue-800 border border-blue-300 rounded-lg bg-blue-50"
                                        role="alert"
                                    >
                                        <span class="sr-only">Info</span>
                                        <p className="text-base font-bold">How pricing plan works?</p>
                                        <p>Once you have added the plan you will be able to customize the limitations of this plan and assign quantity to each feature of all products.</p>
                                    </div>
                                    <div className="flex justify-between items-center">
                                      <button onClick={close} className="bg-gray-500 text-white px-4 py-2 hover:opacity-80 rounded">Cancel</button>
                                      <button onClick={close} className="bg-primary text-white px-4 py-2 hover:opacity-80 rounded">Add this pricing plan</button>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
});

export default AddPricingPlanPopup;
