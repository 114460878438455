import { Component, Fragment } from "react";
import Search_infl from "@assets/search_infl.png";
import Network from "@assets/network.png";
import Questions from "@assets/questions-for-Instagram.png";
import Dashboard_email from "@assets/dashboard_email.webp";
import Keybaord from "@assets/keyboard.webp";
import TopInfluencer from "@assets/top_influencer.webp";
import Instagram from "@assets/instagram.png";
import Arrow from "@assets/Arrow.png";
import Youtube from "@assets/youtube.png";
import Tiktok from "@assets/tiktok.png";
import Topinfluencers from "./components/TopInfluencers";
import { connect } from "react-redux";
import * as brandDashboardActions from "@store/actions/BrandDashboardActions";
import { Helmet } from "react-helmet";
import Btrendy from "../../../constants/Btrendy";
import LinkTo from "@components/global/LinkTo";
import PopularSearch from "@assets/popular_search.webp";
import TopCountry from "@assets/top_country.webp";
import PopularSearches from "./components/popularsearches";
import InfluencerMarketing from "./components/influencermarketing";
import Topsearches from "./components/Topsearches";
import LineMapChart from "./components/GoogleAnalyticsChart";
import GeoChart from "./components/GoogleAnalyticsChart/GeoChart";
import BarChart from "./components/GoogleAnalyticsChart/BarChart";
import SessionTable from "./components/GoogleAnalyticsChart/SessionsTable";
import ViewTable from "./components/GoogleAnalyticsChart/ViewsTable";
import tootltip from "../../../constants/tooltip";
import CohortGraph from "./components/GoogleAnalyticsChart/CohortGraph";
import ActivityOverTime from "@components/Analytics/ActivityOverTime";
import { IoIosArrowDown } from "react-icons/io";
import AnalyticDataRange from "./components/AnalyticDateRange/Index";
import moment from "moment";
import SubHeader from "@components/Header/SubHeader";
import { Link } from "react-router-dom";
import CustomLoader from "@components/global/CustomLoader";
import avatar from "@assets/avatar.webp";
import { Menu, Transition } from "@headlessui/react";
import { HiDotsHorizontal } from "react-icons/hi";

const formatedNumber = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};
var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
const abbreviateNumber = (number) => {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

class BrandDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      demographicsInformation: {},
      dateRangeToggle: false,
      start_date: moment().subtract("days", 28).format("YYYY-MM-DD"),
      end_date: moment().subtract("days", 1).format("YYYY-MM-DD"),
    };
  }

  componentDidMount() {
    const { handlePlatform, clearFilters, fetchBrandDashboardInformation } =
      this.props;
    // const { start_date, end_date } = this.state;

    handlePlatform("instagram");
    clearFilters();
    this.props.fetchTopInfluencersAnalyzed();
    // this.props.fetchGoogleAnalyticsUser({ start_date, end_date });
    // this.props.fetchGoogleAnalyticsActivity({ start_date, end_date });
    this.props.fetchInstagramLists({sortQuery: 'date', platform: 'instagram'});
    fetchBrandDashboardInformation();
  }

  demographicsInformation = async () => {
    const json = await Btrendy.demographicsInformation();
    if (json !== undefined) {
      if (json.status === 200) {
        this.setState({ demographicsInformation: json && json.data });
      }
    }
  };

  onDateChange = (item) => {
    this.setState(
      {
        start_date: moment(item.startDate).format("YYYY-MM-DD"),
        end_date: moment(item.endDate).format("YYYY-MM-DD"),
      },
      () => {
        const { start_date, end_date } = this.state;
        this.props.fetchGoogleAnalyticsUser({ start_date, end_date });
        this.props.fetchGoogleAnalyticsActivity({ start_date, end_date });
      }
    );
  };

  platforms = [
    {
      title: "Instagram",
      pathname: "instagram",
      image : Instagram
    },
    {
      title: "Tiktok",
      pathname: "tiktok",
      image : Tiktok
    },
    {
      title: "Youtube",
      pathname: "youtube",
      image : Youtube
    },
  ];

  render() {
    const { reports, activity,brandlists,refreshData } = this.props;
    const { start_date, end_date } = this.state;
    const url = window.location.href;
    return (
      <div>
        {this.props.isLoading ? (
          <div className="h-[87vh] w-full flex justify-center items-center relative">
            <CustomLoader />
          </div>
        ) : 
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Dashboard | {this.props?.domain ? this.props?.domain : "Btrendy"}</title>
            <link rel="canonical" href={url} />
          </Helmet>
          <SubHeader />
          <div className="pt-10 mb-4 sm:!pb-12">
            <div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
              <Link to={'/discovery/instagram'} className="w-full pb-3 h-max hover:shadow-[0px_10px_30px_#96969640]">
                <img src={Search_infl} alt="Search_infl" className="lg:h-36 w-full" />
                <div className="pt-3">
                  <h6 className="text-white font-semibold">Search influencers</h6>
                  <p className="text-white">Browse 200M+ Influence Profiles</p>
                </div>
              </Link>
              <Link to={'/past-report/instagram'} className="w-full pb-3 h-max hover:shadow-[0px_10px_30px_#96969640]">
                <img src={Network} alt="Network" className="lg:h-36 w-full" />
                <div className="pt-3">
                  <h6 className="text-white font-semibold">Analyze influencers</h6>
                  <p className="text-white">See influencer and audience metrics</p>
                </div>
              </Link>
              <Link to={'/reports'} className="w-full pb-3 h-max hover:shadow-[0px_10px_30px_#96969640]">
                <img src={Questions} alt="Questions" className="lg:h-36 object-top w-full" />
                <div className="pt-3">
                  <h6 className="text-white font-semibold">Story and Post Tracking</h6>
                  <p className="text-white">Track and store influencers mentions of your brand on Tiktok or Instagram (Stories & Posts)</p>
                </div>
              </Link>
              <Link to={'/export'} className="w-full pb-3 h-max hover:shadow-[0px_10px_30px_#96969640]">
                <img src={Keybaord} alt="Keybaord" className="lg:h-36 w-full" />
                <div className="pt-3">
                  <h6 className="text-white font-semibold">Data Exports</h6>
                  <p className="text-white">Access your exports history</p>
                </div>
              </Link>
            </div>
            <div className="mt-12">
              <div className="mb-2">
                <h3 className="flex items-center text-[22px] text-white font-bold">
                  Followers Scanner
                </h3>
              </div>
              <div className="flex flex-wrap">
                <div className="lg:w-1/4 md:w-1/2 w-full flex items-stretch md:pr-6">
                  <div className="shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-[#212437] rounded-[8px] mb-6 p-6 w-full text-center">
                    <img src={Dashboard_email} alt="Dashboard_email" className="h-10 mx-auto" />
                    <h4 className="text-base text-white pt-2 font-medium">Email Match</h4>
                    <LinkTo
                      to={`/influencial/email-match`}
                      className={`bg-transparent border-primary px-6 text-white border rounded-full h-[30px] text-[12px] inline-flex items-center hover:opacity-80 hover:text-white mt-4`}
                      text={"Discover"}
                    />
                  </div>
                </div>
                {this.platforms.map((platform, index) => (
                  <div
                    key={index}
                    className="lg:w-1/4 md:w-1/2 w-full flex items-stretch md:pr-6"
                  >
                    <div className="shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-[#212437] rounded-[8px] mb-6 p-6 w-full text-center">
                      <img src={platform.image} alt="Dashboard_email" className="h-10 mx-auto" />
                      <h4 className="text-base text-white pt-2 whitespace-nowrap font-medium">Influential Followers</h4>
                      <LinkTo
                        to={`/influencial/${platform.pathname}`}
                        className={`bg-transparent border-primary px-6 text-white border rounded-full h-[30px] text-[12px] inline-flex items-center hover:opacity-80 hover:text-white mt-4`}
                        text={"Discover"}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-12">
              {(brandlists || []).length > 0 && (
                <>
                  <div className="mb-2 flex items-center gap-x-4">
                    <h3 className="flex items-center text-[22px] text-white font-bold">
                      Saved lists
                    </h3>
                    <Link to={'/list/instagram'}>
                      <img src={Arrow} alt="Arrow" className="rotate-90 w-8" />
                    </Link>
                  </div>
                  <div
                    className="grid grid-cols-12 gap-5 shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-bgBodyDark rounded-[8px] mb-4 p-4 campaign-cards"
                  >
                    <div className="xl:col-span-3 lg:col-span-4 col-span-12 lg:!mb-0">
                      <div className="flex gap-4 items-center h-full">
                        <div className="text-center shrink-0 w-36">
                          <LinkTo
                            to={"/list/" + brandlists[0].id}
                            state={{ id: brandlists[0].id }}
                            prefix={
                              <img
                                src={brandlists[0].list_avatar ? brandlists[0].list_avatar : avatar}
                                alt={brandlists[0].list_name}
                                className="w-[95px] h-[95px] rounded-full lg:mx-0 mx-auto"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = avatar;
                                }}
                              />
                            }
                          />
                        </div>

                        <div className="lg:text-left text-center text-white break-all w-full">
                          <LinkTo
                            to={"/list/" + brandlists[0].id}
                            state={{ id: brandlists[0].id }}
                            className="font-medium text-white text-[17px] hover:success"
                            text={brandlists[0].list_name ? brandlists[0].list_name : "[Noname]"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-8 lg:col-span-7 col-span-12 md:!mb-0">
                      <div className="grid grid-cols-12 md:gap-3 gap-5 items-center">
                        <div className="md:col-span-3 sm:col-span-6 col-span-12 text-center">
                          <div className="flex justify-center">
                            <p className="pl-1 text-white text-[16px] font-medium">
                              {brandlists[0].listInfluencersCount}
                            </p>
                          </div>
                          <div className="mt-4 border-[1px] border-[#ccc] h-[36px] flex items-center justify-center relative bg-bgBodyDark rounded-[8px]">
                            <span className="font-normal text-[12px] text-white">
                              Influencers
                            </span>
                          </div>
                        </div>
                        <div className="md:col-span-3 sm:col-span-6 col-span-12 text-center">
                          <div className="flex justify-center">
                            <p className="pl-1 text-white text-[16px] font-medium">
                              {formatedNumber(brandlists[0].total_followers ?? 0)}
                            </p>
                          </div>
                          <div className="mt-4 border-[1px] border-[#ccc] h-[36px] flex items-center justify-center relative bg-bgBodyDark rounded-[8px]">
                            <span className="font-normal text-[12px] text-white">
                              Total Reach
                            </span>
                          </div>
                        </div>
                        <div className="md:col-span-3 sm:col-span-6 col-span-12 text-center">
                          <div className="flex justify-center">
                            <p className="pl-1 text-white text-[16px] font-medium">
                              {(brandlists[0].engagement_rate * 100).toFixed(2)}%
                            </p>
                          </div>
                          <div className="mt-4 border-[1px] border-[#ccc] h-[36px] flex items-center justify-center relative bg-bgBodyDark rounded-[8px]">
                            <span className="font-normal text-[12px] text-white">
                              Engagement Rate
                            </span>
                          </div>
                        </div>
                        <div className="md:col-span-3 sm:col-span-6 col-span-12 text-center relative">
                          <div className="flex justify-center">
                            <p className="pl-1 text-white text-[16px] font-medium">
                              {abbreviateNumber(brandlists[0].engagements ?? 0)}
                            </p>
                          </div>
                          <div className="mt-4 border-[1px] border-[#ccc] h-[36px] flex items-center justify-center relative bg-bgBodyDark rounded-[8px]">
                            <span className="font-normal text-[12px] text-white">
                              Total Engagement
                            </span>
                          </div>
                          {brandlists[0]?.brand_name && 
                            <div className="absolute -right-10 top-0">
                              <p className="bg-primary px-2 py-1 text-white rounded">{brandlists[0]?.brand_name}</p>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="lg:col-span-1 hidden col-span-12 md:!mb-0 justify-end items-center">
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button
                            as="div"
                            className="cursor-pointer inline-flex w-full bg-transparent justify-center rounded-md px-4 py-2 focus:outline-none"
                          >
                            <HiDotsHorizontal size={20} className="text-white" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-bgBodyDark shadow-lg ring-1 ring-text-white ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item
                                className="flex w-full items-center text-white hover:text-black rounded-[8px] text-[14px] hover:bg-gray-100 px-[12px] py-[12px] "
                              >
                                <Link to={`/list/${brandlists[0].id}`} state={{ id: brandlists[0].id }} className="text-[14px] p-3">
                                  Detail
                                </Link>
                              </Menu.Item>
                              {refreshData.is_admin && (
                                <Menu.Item
                                  className="flex w-full items-center text-white hover:text-black rounded-[8px] text-[14px] hover:bg-gray-100 px-[12px] py-[12px]"
                                  // onClick={() => deleteList(brandlists[0].id)}
                                >
                                  <button className="text-[14px] p-3">
                                    Delete
                                  </button>
                                </Menu.Item>
                              )}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* {reports?.reports?.length > 0 && (
              <div className="shadow-[0px_4px_5px_#96969640] hidden hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] p-10 pb-20">
                <div className="mb-2 flex justify-between">
                  <h3 className="flex items-center text-[22px] black font-bold">
                    Reports snapshot
                  </h3>
                  <div className="relative">
                    <button
                      className="flex items-center text-white rounded bg-gray-500 hover:bg-gray-400 px-2 py-1"
                      onClick={() => {
                        this.setState({
                          dateRangeToggle: !this.state.dateRangeToggle,
                        });
                      }}
                    >
                      {start_date} - {end_date}{" "}
                      <IoIosArrowDown size={17} color="white" />
                    </button>
                    {this.state.dateRangeToggle && (
                      <div className="absolute top-10 right-0 z-10 border shadow-lg">
                        <AnalyticDataRange
                          startDate={start_date}
                          endDate={end_date}
                          onDateChange={this.onDateChange}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-12">
                  <div className="grid grid-cols-12 gap-5">
                    {reports?.reports?.[0]?.rows && (
                      <div className="border xl:col-span-6 col-span-12 shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                        <LineMapChart data={reports?.reports?.[0]} />
                      </div>
                    )}
                    {reports?.reports?.[1]?.rows && (
                      <div className="border xl:col-span-6 col-span-12 shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                        <p className="black text-[17px] font-medium p-3">
                          Users by Country
                        </p>
                        <div className="grid grid-cols-12 gap-5">
                          <GeoChart data={reports?.reports?.[1]} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-20">
                  <div className="grid grid-cols-12 gap-5">
                    {reports?.reports?.[4]?.rows && (
                      <div className="xl:col-span-3 col-span-12 ">
                        <p className="text-slate-400 mb-2">
                          WHICH PAGES AND SCREENS GET THE MOST VIEWS?
                        </p>
                        <div className="border shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                          <p className="black text-[17px] font-medium p-3">
                            Views by Page title and screen class
                          </p>
                          <ViewTable
                            data={reports?.reports?.[4]}
                            title1={"PAGE TITLE AND SCREEN CLASS"}
                            title2="VIEWS"
                            tootltip1={tootltip.page_title}
                            tootltip2={tootltip.views}
                          />
                        </div>
                      </div>
                    )}

                    {reports?.reports?.[2]?.rows && (
                      <div className="xl:col-span-3 col-span-12 ">
                        <p className="text-slate-400 mb-2">
                          WHAT ARE YOUR TOP EVENTS?
                        </p>
                        <div className="border shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                          <p className="black text-[17px] font-medium p-3">
                            Event count by Event name
                          </p>
                          <ViewTable
                            data={activity?.data?.reports?.[2]}
                            title1={"EVENT NAME"}
                            title2="EVENT COUNT"
                            tootltip1={tootltip.event}
                            tootltip2={tootltip.event_count}
                          />
                        </div>
                      </div>
                    )}
                    {activity?.data?.reports?.[1]?.rows && (
                      <div className="xl:col-span-6 col-span-12 ">
                        <p className="text-slate-400 mb-2">
                          HOW WELL DO YOU RETAIN YOUR USERS?
                        </p>
                        <div className="border shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                          <p className="black text-[17px] font-medium px-3 pt-3">
                            User activity by cohort
                          </p>
                          <p className="mb-2 darkGray text-[14px] px-3">
                            Based on device data only
                          </p>
                          <CohortGraph data={activity} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-20">
                  <div className="grid grid-cols-12 gap-5">
                    {reports?.reports?.[2]?.rows && (
                      <div className="xl:col-span-3 col-span-12 ">
                        <p className="text-slate-400 mb-2">
                          WHERE DO YOUR NEW USERS COME FROM?
                        </p>
                        <div className="border shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                          <p className="black text-[17px] font-medium p-3">
                            New Users by Channel groups
                          </p>
                          <BarChart data={reports?.reports?.[2]} />
                        </div>
                      </div>
                    )}

                    {reports?.reports?.[3]?.rows && (
                      <div className="xl:col-span-3 col-span-12 ">
                        <p className="text-slate-400 mb-2">
                          WHAT ARE YOUR TOP CAMPAIGNS?
                        </p>
                        <div className="border shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                          <p className="black text-[17px] font-medium p-3">
                            Sessions by Channel groups
                          </p>
                          <SessionTable data={reports?.reports?.[3]} />
                        </div>
                      </div>
                    )}

                    {activity?.data?.reports?.[0]?.rows && (
                      <div className="xl:col-span-6 col-span-12 ">
                        <p className="text-slate-400 mb-2 ">
                          HOW ARE ACTIVE USERS TRENDING?
                        </p>

                        <div className="border shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-white rounded-[8px] h-full">
                          <p className="black text-[17px] font-medium p-3">
                            User activity over time
                          </p>
                          <ActivityOverTime />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="mt-12 hidden">
              <div className="shadow-[0px_4px_5px_#96969640] hover:shadow-[0px_10px_30px_#96969640] bg-[#212437] rounded-[8px] p-6">
                <div className="px-[1rem] pt-3 pb-12">
                  <h6 className="mb-2 text-[16px] font-medium text-white">
                    Learn more about influencer marketing{" "}
                  </h6>
                  <p className="mb-6 text-white text-[14px]">
                    Boost your mindset with an expert advice, and earn extra
                    credits after finishing the course
                  </p>
                  <InfluencerMarketing />
                </div>
              </div>
            </div>
            <div className="mt-12 hidden">
              <h3 className="flex items-baseline text-white mb-6 text-[22px] font-bold">
                <img
                  src={PopularSearch}
                  alt="Popular Searches"
                  className="mr-2 h-[26px]"
                />
                Popular Searches
              </h3>
              <PopularSearches navigate={this.props.navigate} />
            </div>
            <div className="mt-12 md:pt-12 hidden">
              <h3 className="flex items-center mb-6 text-white text-[22px] font-bold">
                <img
                  src={TopCountry}
                  alt="Top Countries"
                  className="mr-2 h-[24px]"
                />
                Top Countries
              </h3>
              <Topsearches navigate={this.props.navigate} />
            </div>
            <div className="mt-12 hidden top-influencers">
              <h3 className="flex items-center mb-6 text-white text-[22px] font-bold">
                <img
                  src={TopInfluencer}
                  alt="Top Influencers Analyzed"
                  className="mr-2 h-[24px]"
                />
                Top Influencers Analyzed
              </h3>
              {this.props.topinfluencersAnalyzed &&
              this.props.topinfluencersAnalyzed.length ? (
                <Topinfluencers />
              ) : (
                <div className="text-center w-full py-[5rem] px-[1rem] justify-center text-white text-[2.8rem] font-medium leading-[40px]">
                  We have nothing to show you here.
                </div>
              )}
            </div> */}
          </div>
        </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.BrandDashboardReducer.isLoading,
    influencer_discovered: state.BrandDashboardReducer.influencer_discovered,
    reports: state.BrandDashboardReducer.reports,
    activity: state.BrandDashboardReducer.activity,
    topinfluencersAnalyzed: state.BrandDashboardReducer.topinfluencersAnalyzed,
    brandDashboardInformation: state.influencerSearch.brandDashboardInformation,
    brandlists: state.brandList.brandlists,
    refreshData: state.HeaderReducer.refreshData,
    domain: state?.domain?.data?.company_name,
  };
};

const mapDispatchToProps = (dispatch) => {
  const { actions } = require("@store/redux/InfluencerSearchRedux");
  const { actions:BrandListReduxAction } = require("@store/redux/BrandListRedux");
  return {
    handlePlatform: (data) => {
      actions.handlePlatform(dispatch, data);
    },
    clearFilters: () => {
      actions.clearFilters(dispatch);
    },
    fetchTopInfluencersAnalyzed: () =>
      dispatch(brandDashboardActions.fetchTopInfluencersAnalyzed()),
    fetchGoogleAnalyticsUser: (params) =>
      dispatch(brandDashboardActions.fetchGoogleAnalyticsUser(params)),
    fetchGoogleAnalyticsActivity: (params) =>
      dispatch(brandDashboardActions.fetchGoogleAnalyticsActivity(params)),
    fetchBrandDashboardInformation: () =>
      actions.fetchBrandDashboardInformation(dispatch),
    fetchInstagramLists: (data) => BrandListReduxAction.fetchBrandLists(dispatch, data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandDashboard);
