import React, { useEffect, useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { SET_RESELLER_INFO } from "@store/constants/action-types";
import { useSelector } from "react-redux";
import { validate } from "@constants/helper";

const StepTwo = () => {
    const form = useSelector(store => store.domain?.resellerData) || {};
    const showError = useSelector(store => store.domain?.showError)
    const [color,setColor] = useState('#141625');
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({
            type: SET_RESELLER_INFO,
            payload: { main_color: "#141625" },
        });
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        if(name === "name"){
            sanitizedValue = value.replace(/[^a-zA-Z0-9-]/g, "");// Allows only alphanumeric characters
            
        }
        if(e.target.name === "main_color"){
            document.documentElement.style.setProperty('--bgBody', value)
        }
        dispatch({
            type: SET_RESELLER_INFO,
            payload: { [name]: sanitizedValue },
        });
    };
    return (
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
            <div className="border border-bgBodyDark rounded-lg pb-3">
                <div className="bg-bgBodyDark flex justify-between items-center px-5 py-3 rounded-t-lg">
                    <div className="flex items-center gap-x-4">
                        <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                            <FaRegBuilding size={24} />
                        </div>
                        <p className="text-white">
                            Company name, domain & main color
                        </p>
                    </div>
                    <div className="border-primary border-2 rounded bg-primary/50 px-4 py-2">
                        <p className="text-white">Step 2</p>
                    </div>
                </div>
                <fo className="p-3 mt-3">
                    <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-5 gap-y-3">
                        <div className="flex flex-col gap-2">
                            <label htmlFor="company_name" className="text-white">
                                Name of future software company
                            </label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Company name"
                                onChange={handleChange}
                                value={form?.name || ""}
                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                id="company_name"
                            />
                            {showError &&
                                <div className="text-white">
                                    {validate("name", form?.name)}
                                </div>
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="domain" className="text-white">
                                Sub Domain
                            </label>
                            <input
                                type="text"
                                placeholder="xyz.btrendy.co"
                                name="subdomain"
                                onChange={handleChange}
                                value={form?.subdomain || ""}
                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                id="domain"
                            />
                            {showError && 
                                <div className="text-white">
                                    {validate("subdomain", form?.subdomain)}
                                </div>
                            }
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="color" className="text-white">
                                Main color
                            </label>
                            <div className="rounded-[8px] h-[40px] gap-x-3 bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                                <input
                                    type="color"
                                    name="main_color"
                                    onChange={handleChange}
                                    value={form?.main_color || ""}
                                    className="w-20 h-full bg-transparent"
                                    id="color"
                                />
                                <p className="text-white">{form?.main_color}</p>
                            </div>
                        </div>
                    </div>
                </fo>
            </div>
        </div>
    );
};



export default StepTwo;
