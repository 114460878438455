import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerOperationTopTab from "@components/SettingResellerOperationTopTab";
import React from "react";
import { VscFeedback } from "react-icons/vsc";

const CollectedFeedBack = () => {
    const data = [
        {
            email: "anya.frascer@yahoo.com",
            reason: "Too expensive",
            comment: "I can't afford atm.",
            date: "6 months ago",
        },
        {
            email: "rupenp@cxosync.com",
            reason: "Other",
            comment: "Not needed now. Just testing.",
            date: "2 years ago",
        },
        {
            email: "hey@simonlim.me",
            reason: "Switch to other product",
            comment: "Don't use it",
            date: "2 years ago",
        },
        {
            email: "stripesmith@gmail.com",
            reason: "Other",
            comment: "Will subscribe again",
            date: "2 years ago",
        },
        {
            email: "sezerturhan82@gmail.com",
            reason: "Switch to other product",
            comment: "Email quality",
            date: "2 years ago",
        },
        {
            email: "peter@pontoonsports.com",
            reason: "Not sure how to use",
            comment: "I can't get any contact information",
            date: "2 years ago",
        },
    ];

    return (
        <>
            <SubHeader title={"Collected Feedback"} />
            <SettingResellerOperationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="p-3 space-y-5">
                                <div className="flex items-center gap-x-4">
                                    <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                        <VscFeedback size={20} />
                                    </div>
                                    <p className="text-white">
                                        Collected Feedback
                                    </p>
                                </div>
                                <button className="bg-primary text-white px-4 py-2 rounded hover:opacity-80">
                                    Download feedback data
                                </button>
                                <div
                                    class="flex items-center p-4 text-sm border border-blue-300 rounded-lg bg-blue-50"
                                    role="alert"
                                >
                                    <span class="sr-only">Info</span>
                                    <p>
                                        Find below the reason and the comment of customers who canceled their subscription on your dashboard. 
                                    </p>
                                </div>
                                <div className="overflow-x-auto">
                                    <table className="min-w-full">
                                        <thead>
                                            <tr className="text-left">
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Customer email
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Reason
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Comment
                                                </th>
                                                <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                    Date
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {item.email}
                                                    </td>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {item.reason}
                                                    </td>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {item.comment}
                                                    </td>
                                                    <td className="text-white p-[0.75rem] border-b-[1px] border-borderDark">
                                                        {item.date}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CollectedFeedBack;
