import React, { useEffect } from "react";
import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerConfigurationTopTab from "@components/SettingResellerConfigurationTopTab";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import { useSelector } from "react-redux";
import { validateAllInputs } from "@constants/helper";
import { useDispatch } from "react-redux";
import { SET_SHOW_ERROR } from "@store/constants/action-types";
import { setDomainInfo } from "@store/actions/DomainInfoAction";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ResellerInstallation = () => {
    const form = useSelector(store => store.domain?.resellerData)
    const loader = useSelector(store => store.domain?.requestLoading)
    const appUrl = useSelector(store => store.domain?.appUrl)
    const userData = useSelector(store => store.subAccount?.main)
    const showReseller = useSelector(store => store.HeaderReducer?.showReseller)
    const role = localStorage.getItem('role');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!appUrl?.includes("app.btrendy.co") || !showReseller){
            navigate(-1);
        }

    }, [])
    const submitRequest = () => {
        let err = validateAllInputs(form);
        if (Object.entries(form).length < 5) {
            err = true;
        }
        if (err) {
            dispatch({
                type: SET_SHOW_ERROR,
                payload: true,
            });
            return;
        } else {
            dispatch({
                type: SET_SHOW_ERROR,
                payload: false,
            });
        }

        const formData = new FormData();

        if (Object.entries(form).forEach(([key, value]) => {
            formData.append(key, value);
        }));
        if (userData?.email) {
            formData.append('email', userData?.email);
        }
        dispatch(setDomainInfo(formData));

    }
    if (!appUrl?.includes("app.btrendy.co") || !showReseller){
        return (
            <div className="text-white h-screen flex justify-center flex-col gap-3 items-center">
                <h1 className="text-red-600 font-bold text-2xl">Access Denied!</h1>
                <p className="text-lg">You are not authorized to access this page</p>
            </div>
        )
    }
    return (
        <>
            <SubHeader title={"Installation"} />
            <SettingResellerConfigurationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div
                            class="flex items-center p-4 text-sm border border-blue-300 rounded-lg bg-blue-50"
                            role="alert"
                        >
                            <span class="sr-only">Info</span>
                            <strong className="me-3">Pro tips</strong>
                            <p>
                                Go for a .com domain to build a strong
                                globle brand. We will automatically install
                                3 monthlys and yearly plans on your software
                                (you can edit your pricing after the
                                installation)
                            </p>
                        </div>
                        {/* <StepOne /> */}
                        <StepTwo />
                        {/* <StepThree /> */}
                        <StepFour />
                        <div className="pt-5 w-full">

                            <button onClick={submitRequest} className="bg-primary w-full hover:opacity-80 text-white flex justify-center items-center py-2 px-4 rounded">
                            {
                                loader ?
                                <FaSpinner className="animate-[spin_2s_linear_infinite] text-white inline" /> :
                                'Click to check if the Configuration is ready'
                            }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ResellerInstallation;
