import AddPricingPlanPopup from "@components/Popup/AddPricingPlanPopup";
import React, { useRef } from "react";
import { RiRepeatFill } from "react-icons/ri";

const PricingPlan = () => {
    const addPricingRef = useRef(null);

    const openModal = ()=>{
        addPricingRef.current?.open();
    }
    
    return (
        <>
            <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                <div className="p-3">
                    <div className="flex justify-between items-center py-3 rounded-t-lg">
                        <div className="flex items-center gap-x-4">
                            <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                <RiRepeatFill size={24} />
                            </div>
                            <p className="text-white">Pricing Plans</p>
                        </div>
                        <button onClick={openModal} className="bg-primary px-4 py-2 rounded hover:opacity-80 text-white">
                            Add a new pricing plan
                        </button>
                    </div>
                    <div className="border-y py-5 flex justify-between items-center">
                        <div className="flex flex-col gap-y-2">
                            <select className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                                <option
                                    value="trial"
                                    className="bg-bgBodyDark"
                                >
                                    Trial
                                </option>
                                <option
                                    value="each_month"
                                    className="bg-bgBodyDark"
                                >
                                    Each month plan
                                </option>
                                <option
                                    value="each_year"
                                    className="bg-bgBodyDark"
                                >
                                    Each year plan
                                </option>
                                <option
                                    value="each_3_months"
                                    className="bg-bgBodyDark"
                                >
                                    Each 3 months plan
                                </option>
                                <option
                                    value="each_6_months"
                                    className="bg-bgBodyDark"
                                >
                                    Each 6 months plan
                                </option>
                                <option
                                    value="each_9_months"
                                    className="bg-bgBodyDark"
                                >
                                    Each 9 months plan
                                </option>
                            </select>
                        </div>
                        <div className="text-white">
                            <p>Business model configuration</p>
                            <p className="flex items-center gap-x-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="2em"
                                    height="2em"
                                    fill="none"
                                    className="text-primary"
                                    stroke="currentColor"
                                    strokeWidth={0}
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        stroke="none"
                                        d="M7.182 9.172 5.768 7.757 1.525 12l4.243 4.243 1.414-1.415L4.353 12l2.829-2.828ZM16.818 14.828l1.414 1.415L22.475 12l-4.243-4.243-1.414 1.415L19.646 12l-2.828 2.828Z"
                                    />
                                    <path
                                        fill="currentColor"
                                        fillRule="evenodd"
                                        stroke="none"
                                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                Scroll horizontally to view more plans
                            </p>
                        </div>
                    </div>
                    <div className="flex overflow-x-auto gap-5">
                        <div className="flex shrink-0 flex-col mt-5 w-full space-y-3">
                            <p className="text-white">
                                If you selected the business model with a trial,
                                configure below the limitation of the trial.
                            </p>
                            <div className="inline-flex flex-col space-y-2">
                                <label
                                    htmlFor="plan_name"
                                    className="text-white"
                                >
                                    Plan name:
                                </label>
                                <input
                                    type="text"
                                    placeholder="Plan name"
                                    className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                />
                            </div>
                            <label
                                htmlFor="unlimited"
                                className="flex items-center cursor-pointer relative text-white text-[14px]"
                            >
                                <input
                                    id="unlimited"
                                    type="radio"
                                    name="users"
                                    className="absolute opacity-0 z-[0] peer"
                                />
                                <span className="peer-checked:bg-primary shadow-[inset_0px_0px_0px_3px_white] border-2 border-primary w-[18px] h-[18px] inline-block mr-2 rounded-full shrink-0 z-[10]"></span>
                                Unlimited users
                            </label>
                            <label
                                htmlFor="limited"
                                className="flex items-center cursor-pointer relative text-white text-[14px]"
                            >
                                <input
                                    id="limited"
                                    type="radio"
                                    name="users"
                                    className="absolute opacity-0 z-[0] peer"
                                />
                                <span className="peer-checked:bg-primary shadow-[inset_0px_0px_0px_3px_white] border-2 border-primary w-[18px] h-[18px] inline-block mr-2 rounded-full shrink-0 z-[10]"></span>
                                Limited users
                            </label>
                            <input
                                type="number"
                                placeholder="Enter no of users"
                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                            />
                            <div className="bg-bgBodyDark p-3 rounded">
                                <div className="flex justify-between items-center">
                                    <p className="text-white">
                                        Number of credits/
                                        <span className="text-green-500">
                                            month
                                        </span>
                                    </p>
                                    <p className="text-white/80">
                                        Maximum limit allowed for trail: 100
                                    </p>
                                </div>
                                <div className="flex rounded-[8px] items-center border-[#ced4da] mt-5">
                                    <p className="h-[40px] bg-gray-400 flex justify-center items-center px-4 shrink-0">
                                        Number of credits/Month
                                    </p>
                                    <input
                                        type="number"
                                        className="h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px]  focus-visible:outline-0 focus:border-primary"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddPricingPlanPopup ref={addPricingRef} />
        </>
    );
};

export default PricingPlan;
