import React from "react";
import { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { RiTv2Fill } from "react-icons/ri";

const SoftwareBundles = () => {
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (id) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleCheckAll = () => {
        const allChecked = tableData.reduce((acc, item) => {
            acc[item.id] = true;
            return acc;
        }, {});
        setCheckedItems(allChecked);
    };

    const handleUncheckAll = () => {
        setCheckedItems({});
    };
    
    const tableData = [
        {
            id: 1,
            number: 5,
            title: "Lists",
        },
        {
            id: 2,
            number: 4,
            title: "Followers Scanner",
        },
        {
            id: 3,
            number: 3,
            title: "Mention Trackers",
        },
        {
            id: 4,
            number: 2,
            title: "Analyzer",
        },
        {
            id: 4,
            number: 2,
            title: "Search & discovery",
        },
    ];
    return (
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
            <div className="p-3">
                <div className="flex justify-between items-center py-3 rounded-t-lg">
                    <div className="flex items-center gap-x-4">
                        <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                            <RiTv2Fill size={24} />
                        </div>
                        <p className="text-white">
                            Create a bundle of software
                        </p>
                    </div>
                </div>
                <div className="mt-3">
                    <div className="mb-3 flex items-center gap-x-3">
                        <button
                            className="text-white bg-primary hover:opacity-80 px-4 py-2 rounded-lg"
                            onClick={handleCheckAll}
                        >
                            Check all
                        </button>
                        <button
                            className="text-white bg-primary hover:opacity-80 px-4 py-2 rounded-lg"
                            onClick={handleUncheckAll}
                        >
                            Uncheck all
                        </button>
                    </div>
                    <div className="w-full overflow-x-auto">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    <th className="text-[15px] text-white font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                        <FaCheck size={16} />
                                    </th>
                                    <th className="text-[15px] text-white text-start font-medium p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                        Include A Number/Title
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((data) => {
                                    return (
                                        <tr key={data.id}>
                                            <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                <label
                                                    htmlFor={`checkbox-${data.id}`}
                                                    className="cursor-pointer flex items-center text-[15px] font-normal mr-2"
                                                >
                                                    <input
                                                        id={`checkbox-${data.id}`}
                                                        type="checkbox"
                                                        checked={
                                                            !!checkedItems[
                                                                data.id
                                                            ]
                                                        }
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                data.id
                                                            )
                                                        }
                                                        className="hidden peer"
                                                    />
                                                    <span className="mr-3 peer-checked:bg-primary bg-white h-[18px] w-[18px] before:content-[''] relative before:absolute before:bottom-[4.2px] before:left-[2.1px] before:h-[5px] before:w-[10px] before:border-b-2 before:border-l-2 before:-rotate-[45deg] before:border-white inline-block border-2 border-primary rounded-sm"></span>
                                                </label>
                                            </td>
                                            <td className="font-normal text-white text-[14px] p-[0.75rem] border-t-[1px] border-borderDark border-b-[1px]">
                                                <div className="flex justify-between items-center">
                                                    <div className="flex items-center gap-x-3">
                                                        <div
                                                            class="flex items-center p-2 w-10 justify-center shrink-0 text-blue-800 border border-blue-300 rounded-lg bg-blue-50"
                                                            role="alert"
                                                        >
                                                            <span class="sr-only">
                                                                Info
                                                            </span>
                                                            <p>{data.number}</p>
                                                        </div>
                                                        {data.title}
                                                    </div>
                                                    <div
                                                        class="flex items-center p-2 justify-center shrink-0 text-green-800 border border-green-300 rounded-lg bg-green-100"
                                                        role="alert"
                                                    >
                                                        <span class="sr-only">
                                                            Info
                                                        </span>
                                                        <p>$ Top Seller</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SoftwareBundles;
