import SubHeader from "@components/Header/SubHeader";
import SettingBrandSidebar from "@components/SettingBrandSidebar";
import SettingResellerConfigurationTopTab from "@components/SettingResellerConfigurationTopTab";
import { FaRegMessage } from "react-icons/fa6";

const CustomSMTP = () => {
    return (
        <>
            <SubHeader title={"Custom SMTP"} />
            <SettingResellerConfigurationTopTab />
            <div className="mb-12">
                <div className="grid grid-cols-12 gap-5">
                    <div className="md:col-span-3 sm:col-span-6 lg:col-start-1 sm:col-start-4 col-span-12">
                        <SettingBrandSidebar />
                    </div>
                    <div className="md:col-span-9 space-y-4 col-span-12 mt-12 md:!mt-0">
                        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
                            <div className="p-3">
                                <div className="flex items-center gap-x-4">
                                    <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                                        <FaRegMessage size={17} />
                                    </div>
                                    <p className="text-white">
                                        Custom SMTP for transactional emails
                                    </p>
                                </div>
                                <div
                                    class="flex items-center p-2 w-full mt-5 justify-center shrink-0 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50"
                                    role="alert"
                                >
                                    <span class="sr-only">Info</span>
                                    <p>
                                        <span className="font-semibold">
                                            Important:
                                        </span>{" "}
                                        Avoid using personal Google or Outlook
                                        accounts for SMTP connections here.
                                        Connect a dedicated SMTP service like
                                        Amazon SES below for safer high-volume
                                        and transactional emails.
                                    </p>
                                </div>
                                <div className="mt-5 space-y-5">
                                    <div className="grid grid-cols-2 gap-5">
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="host"
                                                className="text-white"
                                            >
                                                Host
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="smtp.example.com"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="Port"
                                                className="text-white"
                                            >
                                                Host
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="000"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="username"
                                                className="text-white"
                                            >
                                                Username
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="support@mycompany.com"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="password"
                                                className="text-white"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                placeholder="********"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="full_name"
                                                className="text-white"
                                            >
                                                Sender Full Name
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="John Doe"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="email"
                                                className="text-white"
                                            >
                                                Sender Email
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="support@mycompany.com"
                                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="authentication"
                                                className="text-white"
                                            >
                                                Type of authentication
                                            </label>
                                            <select className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                                                <option
                                                    value="normal_authentication"
                                                    className="bg-bgBodyDark"
                                                >
                                                    Normal authentication
                                                </option>
                                            </select>
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="authentication"
                                                className="text-white"
                                            >
                                                Force SSL or TLS
                                            </label>
                                            <select className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary">
                                                <option
                                                    value="ssl"
                                                    className="bg-bgBodyDark"
                                                >
                                                    Forse SSL
                                                </option>
                                                <option
                                                    value="tsl"
                                                    className="bg-bgBodyDark"
                                                >
                                                    Forse TSL
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* <button className="bg-primary px-4 py-2 rounded hover:opacity-80 text-white">Update the transactional SMTP configuration</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomSMTP;
