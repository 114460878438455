import React from "react";
import { FaFileInvoiceDollar } from "react-icons/fa";

const BusinessModel = () => {
    return (
        <div className="bg-bgBodyLight shadow-[0px_4px_5px_#96969640] rounded-[8px] p-5">
            <div className="p-3">
                <div className="flex justify-between items-center py-3 rounded-t-lg">
                    <div className="flex items-center gap-x-4">
                        <div className="text-white bg-primary/50 rounded h-10 w-10 flex justify-center items-center">
                            <FaFileInvoiceDollar size={24} />
                        </div>
                        <p className="text-white">Business Model</p>
                    </div>
                </div>
                <div>
                    <div
                        class="flex items-start p-4 my-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50"
                        role="alert"
                    >
                        <svg
                            class="flex-shrink-0 inline w-4 h-4 me-3 mt-1"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span class="sr-only">Info</span>
                        <div className="space-y-3">
                            <p>
                                Never delete a pricing plan or a currency if you
                                already have active customers! You will lose
                                revenue if you do this. Please sell in USD by
                                default (and add your local currencies as
                                options), the market is huge, adding only local
                                currencies is money losing for you!
                            </p>
                            <p>
                                Pricing regulation: Please do not add low
                                pricing. You are here to make money. Minimum
                                $49/month for a limited plan (or 2,3 products)
                                and minimum $79 or $99/month for Unlimited plan
                                (all the products). If you do not respect this
                                average price Sell SaaS partnership will be
                                suspended. Chat with us if you have any
                                questions about the pricing/business model you
                                want to put in place.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <label htmlFor="" className="text-white">
                            Type of business model
                        </label>
                        <select
                            className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                        >
                            <option value="">
                                x day trail, credit card is required, automatic
                                billing at the end of the trail
                            </option>
                        </select>
                    </div>
                    <div className="grid grid-cols-2 gap-5 mt-3">
                        <div className="flex flex-col gap-y-2">
                            <label htmlFor="" className="text-white">
                                Default currency to display
                            </label>
                            <select
                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                            >
                                <option value="usd">
                                    USD ($)
                                </option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-y-2">
                            <label htmlFor="" className="text-white">
                                Number of days for the trial
                            </label>
                            <input
                                type="number"
                                placeholder="Number of days"
                                className="rounded-[8px] h-[40px] bg-transparent text-white inline-flex w-full items-center px-3 border-[1px] border-[#ced4da] focus-visible:outline-0 focus:border-primary"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessModel;
